import { Config } from "@/models/modeloConfig";

export const config:Config = {
    "appName": "Abastos company",
    "slogan": "Aprende a tu ritmo",
    "appStyle": {
        "primaryColor": "#FCDD09",
        "secondaryColor": "#DA121A",
        "textButtonColor": "#fff",
        "barReproductionColor": "#DA121A"
    },
    "logoURL": {
        "small": "https://nomisstatic.s3.amazonaws.com/public/base/logos/s.svg",
        "medium": "https://nomisstatic.s3.amazonaws.com/public/base/logos/m.svg",
        "large": "https://nomisstatic.s3.amazonaws.com/public/base/logos/l.svg",
        "xlarge": "https://nomisstatic.s3.amazonaws.com/public/base/logos/xl.svg"
    },
    "favicon": {
        "ico": "https://nomisstatic.s3.amazonaws.com/public/base/favicon/favicon.ico",
        "apple-touch-icon": "https://nomisstatic.s3.amazonaws.com/public/base/favicon/apple-touch-icon.png",
        "favicon-16x16": "https://nomisstatic.s3.amazonaws.com/public/base/favicon/favicon-16x16.png",
        "favicon-32x32": "https://nomisstatic.s3.amazonaws.com/public/base/favicon/favicon-32x32.png",
        "mstile-150x150": "https://nomisstatic.s3.amazonaws.com/public/base/favicon/mstile-150x150.png",
        "safari-pinned-tab": "https://nomisstatic.s3.amazonaws.com/public/base/favicon/safari-pinned-tab.svg",
        "android-chrome-192x192": "https://nomisstatic.s3.amazonaws.com/public/base/favicon/android-chrome-192x192.png",
        "android-chrome-512x512": "https://nomisstatic.s3.amazonaws.com/public/base/favicon/android-chrome-512x512.png"
    },
    "apiEndpoints": {
        "baseURL": "https://api.orquesta.services/api/v2"
    },
    "socialLinks": {
        "facebook": "https://www.facebook.com/myapp",
        "twitter": "https://twitter.com/myapp",
        "instagram": "https://instagram.com/myapp",
        "linkedin": "https://linkedin.com/company/myapp"
    },
    "contactInfo": {
        "email": "hello@holasimon.ai"
    },
    "privacyPolicyURL": "https://holasimon.ai",
    "termsOfServiceURL": "https://holasimon.ai",
    "analytics": {
        "googleAnalyticsId": "G-HE21Z3QK32",
        "hotjarId": "3623385",
        "mixpanelToken": "7b4b460a268b3b383934050789282462"
    },
    "featureFlags": {
        "enablePoints": true,
        "enableStore": true,
        "enableHelp": true
    },
    "videoPlayback": {
        "autoPlay": true,
        "defaultQuality": "720p"
    },
    "userInteraction": {
        "enableSharing": true,
        "enableSaveForLater": true,
        "enableLikes": true,
        "enableUserRegistration": true,
        "enableUserProfile": true
    },
    "style": {
        "colorPalette": {
            "primary": {
                "light": {
                    "light": "#fff0b3",
                    "medium": "#F9D53E",
                    "dark": "#d6af0b"
                },
                "dark": {
                    "light": "#a9c8ed",
                    "medium": "#2657b6",
                    "dark": "#113972"
                }
            },
            "secondary": {
                "light": {
                    "light": "#F7F7E8",
                    "medium": "#F5F7DC",
                    "dark": "#E1E2B3"
                }
            },
            "functional": {
                "dark": {
                    "light": "#383838",
                    "medium": "#1C1B1A",
                    "dark": "#000000"
                },
                "light": {
                    "light": "#DEDFE3",
                    "medium": "#BCBEC6",
                    "dark": "#898B92"
                },
                "extraLight": {
                    "light": "#FFFFFF",
                    "medium": "#f4f4f4"
                }
            },
            "system": {
                "information": {
                    "light": "#A7DEF6",
                    "medium": "#49BDEF",
                    "dark": "#368AAF"
                },
                "success": {
                    "light": "#b1e0b6",
                    "medium": "#30C44B",
                    "dark": "#30884A"
                },
                "warning": {
                    "light": "#F9D4CF",
                    "medium": "#ff8055",
                    "dark": "#ea4b21"
                },
                "error": {
                    "light": "#ffdcec",
                    "medium": "#eb2060",
                    "dark": "#9b0e48"
                }
            }
        }
    },
    "dateFormats": {
        "shortDate": "MM/DD/YYYY",
        "longDate": "MMMM DD, YYYY",
        "shortTime": "HH:mm",
        "longTime": "HH:mm:ss"
    },
    "locale": {
        "languages": [
            "en",
            "es"
        ],
        "defaultLanguage": "en",
        "currency": "USD"
    },
    "app_id": 23
}