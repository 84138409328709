export enum LOGS_FILTERS {
  DATE_AND_HOUR = 'created_at',
  TYPE = 'type',
}

export enum LOGS_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Log {
  id: number
  type: string
  detail: string
  created_at: string
  created_at_client: string
}