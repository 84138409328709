import {useLocation, useNavigate} from 'react-router';
import {usePersonalizationCompany} from '../hooks/usePersonalizationCompany';
import {TabCompany} from '../layout/TabCompany';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {Config} from '@/models/modeloConfig';
import cn from 'classnames';
import {PrimaryButton, SecondaryButton} from '@/components';
import {TitleInput} from '../components/TitleInput';
import {ButtonsMobile, ProgressBarMobile} from '../views/MobileComponents';
import {ToggleSwitch} from '@/components/Inputs/ToggleSwitch';
import {ChooseVideo} from '../modals/ChooseVideo.modal';
import {TextArea} from '../components/TextArea';
import {InputText} from '../components/InputText';
import {Spinner} from '@/components/Spinners/Spinner';
import {t} from 'i18next';
import {useEffect, useState} from 'react';

const defaultValuesAdapter = (configuration: Config): any => {
  return {
    appStyle: {
      textButtonColor: configuration.appStyle?.textButtonColor ?? '#000000',
      barReproductionColor: configuration.appStyle?.barReproductionColor ?? '#000000',
      primaryColor: configuration.appStyle?.primaryColor ?? '#000000',
      secondaryColor: configuration.appStyle?.secondaryColor ?? '#000000'
    },
    userInteraction: {
      enableUserRegistration: configuration?.userInteraction?.enableUserRegistration ?? false,
      enableUserProfile: configuration?.userInteraction?.enableUserProfile ?? false
    },
    featureFlags: {
      enablePoints: configuration?.featureFlags?.enablePoints ?? false,
      redirectOnClose: {
        showButton: configuration?.featureFlags?.redirectOnClose?.showButton || false,
        redirectURL: configuration?.featureFlags?.redirectOnClose?.redirectURL || '/logout'
      },
      enableStore: configuration?.featureFlags?.enableStore ?? false
    },
    home_data: {
      description: configuration?.home_data?.description ?? '',
      title: configuration?.home_data?.title ?? ''
    },
    modules_data: {
      description: configuration?.modules_data?.description ?? '',
      title: configuration?.modules_data?.title ?? ''
    }
  };
};

interface PersonalizationEmpresasFormProps {
  status: string;
  configuration: Config;
  loaderImage: any;
  crudDisabled: boolean;
  loadLoaderImageWithValidations: any;
  viewing: () => void;
  editing: () => void;
  transitionImage: any;
  loadTransitionImageWithValidations: any;
  isOpen: string | undefined;
  setIsOpen: any;
  moduleVideoSelected: any;
  initialVideoSelected: any;
  updateCompany: any;
}

interface FormProps {
  appStyle: {
    textButtonColor: string;
    barReproductionColor: string;
    primaryColor: string;
    secondaryColor: string;
  };
  featureFlags: {
    enablePoints: boolean;
    redirectOnClose: {
      showButton: boolean;
      redirectURL: string | null;
    };
    enableStore: boolean;
  };
  userInteraction: {
    enableUserRegistration: boolean;
    enableUserProfile: boolean;
  };
  home_data: {
    description: string;
    title: string;
  };
  modules_data: {
    description: string;
    title: string;
  };
}

const PersonalizationEmpresasForm: React.FC<PersonalizationEmpresasFormProps> = ({
                                                                                   setIsOpen,
                                                                                   moduleVideoSelected,
                                                                                   initialVideoSelected,
                                                                                   transitionImage,
                                                                                   loadTransitionImageWithValidations,
                                                                                   status,
                                                                                   configuration,
                                                                                   crudDisabled,
                                                                                   loaderImage,
                                                                                   loadLoaderImageWithValidations,
                                                                                   updateCompany
                                                                                 }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch
  } = useForm<FormProps>({
    defaultValues: {...defaultValuesAdapter(configuration)}
  });

  const onSubmit = async (data: any) => {
    updateCompany({
      ...data,
      appStyle: {
        ...watch('appStyle'),
        shadowColor: shadowColor,
        colorsButton: colorsButton,
        backgroundRegister: backgroundRegister,
        colorRegister: colorRegister
      },
      cardRequestRegistration: cardRequestRegistration
    });
  };

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault();
    navigate('/empresas');
  };

  const handleChooseLogoLoader = (event: React.FormEvent) => {
    event.preventDefault();
    const file = (event.target as HTMLInputElement).files?.[0];
    loadLoaderImageWithValidations(file);
  };

  const handleChooseLogoTransition = (event: React.FormEvent) => {
    event.preventDefault();
    const file = (event.target as HTMLInputElement).files?.[0];
    loadTransitionImageWithValidations(file);
  };

  const disabled = status === 'viewing';

  const handleModuleOpenModal = (event: React.FormEvent) => {
    event.preventDefault();
    setIsOpen('moduleModal');
  };

  const handleInitialOpenModal = (event: React.FormEvent) => {
    event.preventDefault();
    setIsOpen('initialModal');
  };

  const infoStatus: any = {
    viewing: {
      title: t('pages.companies.customCompany'),
      message: ''
    },
    editing: {
      title: t('pages.companies.customCompany'),
      message: t('pages.companies.customCompanyMessage')
    },
    creating: {
      title: t('pages.companies.customCompany'),
      message: t('pages.companies.customCompanyMessage')
    }
  };

  const [showInputClose, setShowInputClose] = useState<boolean>(configuration?.featureFlags?.redirectOnClose?.showButton || false);

  const [shadowColor, setShadowColor] = useState<string>('#ffffff');

  const [backgroundRegister, setBackgroundRegister] = useState<string>('#ffffff');

  const [colorRegister, setColorRegister] = useState<string>('#000000');

  const [colorsButton, setColorsButtons] = useState<{ backgroundButton: string; textButton: string }>({
    backgroundButton: '#ffffff',
    textButton: '#000000'
  });

  const [cardRequestRegistration, setCardRequestRegistration] = useState<{
    title: string;
    text: string;
  }>({
    title: '¡Aprende con Simón en 5 minutos!',
    text: 'Mejora tus habilidades desde tu celular con videos 100% prácticos y entretenidos.'
  });

  useEffect(() => {
    if (configuration.appStyle?.shadowColor) {
      setShadowColor(configuration.appStyle.shadowColor);
    }
    if (configuration.appStyle?.colorsButton) {
      setColorsButtons(configuration.appStyle.colorsButton);
    }
    if (configuration.cardRequestRegistration && validateObject(configuration.cardRequestRegistration)) {
      setCardRequestRegistration(configuration.cardRequestRegistration);
    }
    if (configuration.appStyle?.backgroundRegister) {
      setBackgroundRegister(configuration.appStyle.backgroundRegister);
    }
    if (configuration.appStyle?.colorRegister) {
      setColorRegister(configuration.appStyle.colorRegister);
    }
  }, []);

  function validateObject(obj: { title?: string; text?: string }): boolean {
    return obj.title !== undefined && obj.title.trim() !== '' && obj.text !== undefined && obj.text.trim() !== '';
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='bg-functional-el-l flex items-center justify-start px-10 py-7'>
          <Link to='/empresas'>
            <span className='material-symbols-outlined mr-3 cursor-pointer border p-2 rounded'>arrow_back</span>
          </Link>
          <div>
            <p className={`font-bold text-2xl text-functional-d-l`}>{infoStatus[status]?.title}</p>
            <p className={`font-medium text-functional-d-l`}>{infoStatus[status]?.message}</p>
          </div>
        </div>
        <div className='m-10 p-10 bg-functional-el-l rounded-3xl shadow-lg'>
          <div className={cn('flex')}>
            <div className='w-1/2'>
              <div className='row-span-2 p-4'>
                <TitleInput>{t('pages.companies.logoLoader')}</TitleInput>
                <p>{t('pages.companies.selectLogo')}</p>
                <div className='flex flex-col items-center justify-center w-5/6'>
                  <div className={' my-2 h-[250px] w-[150px] row-span-2'}>
                    <img
                      src={loaderImage?.image_path || '/splash-loading.svg'}
                      alt=''
                      className='row-span-2 w-full h-full object-contain bg-primary-l-m'
                    />
                  </div>
                  <input
                    disabled={disabled}
                    type='file'
                    id='actual-btn'
                    accept='image/jpg, image/jpeg, image/png'
                    onChange={handleChooseLogoLoader}
                    hidden
                  />
                  <label
                    htmlFor='actual-btn'
                    className={cn(
                      'disabled:cursor-no-drop self-end w-full bg-[#9A9A9A] text-lg flex items-center justify-center text-functional-el-l rounded-lg max-w-52 py-3 px-3 ml-1 h-14',
                      {'cursor-no-drop': disabled, 'cursor-pointer': !disabled}
                    )}>
                    <span className='material-symbols-outlined bg-functional-el-l text-functional-l-d mr-3.5'>add</span>
                    {t('generics.upload')} (300x500px en png)
                  </label>
                </div>
              </div>
              <div className='row-span-2 p-4'>
                <TitleInput>{t('pages.companies.transitionPicture')}</TitleInput>
                <p>{t('pages.companies.selectTransition')}</p>
                <div className='flex flex-col items-center justify-center w-5/6'>
                  <div className={' my-2 h-[250px] w-[150px] row-span-2'}>
                    <img
                      src={transitionImage?.image_path || '/splash-loading.svg'}
                      alt=''
                      className='row-span-2 w-full h-full object-contain bg-primary-l-m'
                    />
                  </div>
                  <input
                    disabled={disabled}
                    type='file'
                    id='transition-btn'
                    accept='image/jpg, image/jpeg, image/png'
                    onChange={handleChooseLogoTransition}
                    hidden
                  />
                  <label
                    htmlFor='transition-btn'
                    className={cn(
                      'disabled:cursor-no-drop self-end w-full bg-[#9A9A9A] text-lg flex items-center justify-center text-functional-el-l rounded-lg max-w-52 py-3 px-3 ml-1 h-14',
                      {'cursor-no-drop': disabled, 'cursor-pointer': !disabled}
                    )}>
                    <span className='material-symbols-outlined bg-functional-el-l text-functional-l-d mr-3.5'>add</span>
                    {t('generics.upload')} (300x500px en png)
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div className='my-2'>
                <TitleInput>{t('pages.companies.customColors')} </TitleInput>
                <p>{t('pages.companies.colorsMessage')}</p>
              </div>
              <div className='grid grid-cols-2 grid-rows-2'>
                <label className='grid grid-cols-4 my-5' htmlFor='appStyle.primaryColor'>
                  <p
                    className='text-lg font-semibold col-span-2 leading-none self-center'>{t('pages.companies.primaryColor')}</p>
                  <input
                    id='appStyle.primaryColor'
                    className='mx-3 self-center'
                    type='color'
                    {...register('appStyle.primaryColor')}
                  />
                  <p className='self-center'>{watch('appStyle.primaryColor')}</p>
                </label>
                <label className='grid grid-cols-4 my-5' htmlFor='appStyle.secondaryColor'>
                  <p className='text-lg font-semibold col-span-2 leading-none self-center'>
                    {t('pages.companies.secondaryColor')}
                  </p>
                  <input
                    id='appStyle.secondaryColor'
                    className='mx-3 self-center'
                    type='color'
                    {...register('appStyle.secondaryColor')}
                  />
                  <p className='self-center'>{watch('appStyle.secondaryColor')}</p>
                </label>
                <label className='grid grid-cols-4 my-5' htmlFor='appStyle.barReproductionColor'>
                  <p
                    className='text-lg font-semibold col-span-2 leading-none self-center'>{t('pages.companies.barColor')}</p>
                  <input
                    id='appStyle.barReproductionColor'
                    className='mx-3 self-center'
                    type='color'
                    {...register('appStyle.barReproductionColor')}
                  />
                  <p className='self-center'>{watch('appStyle.barReproductionColor')}</p>
                </label>
                <label className='grid grid-cols-4 my-5' htmlFor='appStyle.textButtonColor'>
                  <p
                    className='text-lg font-semibold col-span-2 leading-none self-center'>{t('pages.companies.textColor')}</p>
                  <input
                    id='appStyle.textButtonColor'
                    className='mx-3 self-center'
                    type='color'
                    {...register('appStyle.textButtonColor')}
                  />
                  <p className='self-center'>{watch('appStyle.textButtonColor')}</p>
                </label>
                <label className='grid grid-cols-4 my-5' htmlFor='appStyle.textButtonColor'>
                  <p
                    className='text-lg font-semibold col-span-2 leading-none self-center'>{t('pages.companies.shadowColor')}</p>
                  <input
                    type='color'
                    value={shadowColor}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setShadowColor(event.target.value);
                    }}
                    className='mx-3 self-center'
                  />
                  <p className='self-center'>{shadowColor}</p>
                </label>
                <label className='grid grid-cols-4 my-5' htmlFor='appStyle.textButtonColor'>
                  <p className='text-lg font-semibold col-span-2 leading-none self-center'>
                    {t('pages.companies.distinctiveBackground')}
                  </p>
                  <input
                    type='color'
                    value={backgroundRegister}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setBackgroundRegister(event.target.value);
                    }}
                    className='mx-3 self-center'
                  />
                  <p className='self-center'>{backgroundRegister}</p>
                </label>
                <label className='grid grid-cols-4 my-5' htmlFor='appStyle.textButtonColor'>
                  <p className='text-lg font-semibold col-span-2 leading-none self-center'>
                    {t('pages.companies.distinctiveColor')}
                  </p>
                  <input
                    type='color'
                    value={colorRegister}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setColorRegister(event.target.value);
                    }}
                    className='mx-3 self-center'
                  />
                  <p className='self-center'>{colorRegister}</p>
                </label>
              </div>
              <p
                className='text-lg font-bold col-span-2 leading-none self-center my-5'>{t('pages.companies.preview')}</p>
              <div className='relative flex justify-around items-center mt-10 overflow-hidden w-[500px]'>
                <ProgressBarMobile
                  barColor={watch('appStyle.barReproductionColor')}
                  primaryColor={watch('appStyle.primaryColor')}
                />
                <ButtonsMobile buttonsColor={watch('appStyle.textButtonColor')}
                               secondaryColor={watch('appStyle.secondaryColor')}/>
                <div
                  className='absolute top-0 left-0 w-full'
                  style={{
                    boxShadow: `0px 0px 100px 100px ${shadowColor}`,
                    opacity: 0.5
                  }}
                />
                <div
                  className='absolute bottom-0 left-0 w-full'
                  style={{
                    boxShadow: `0px 0px 100px 100px ${shadowColor}`,
                    opacity: 0.5
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='bg-functional-el-l rounded-3xl shadow-lg m-10 p-10'>
          <div className='w-full flex justify-evenly'>
            <div className='w-[350px] flex flex-col gap-5 justify-center'>
              <p>{t('pages.companies.cardRegitrationDescription')}</p>
              <div className='flex flex-col gap-1'>
                <span className='text-[18px]'>{t('pages.companies.titleCard')}</span>
                <input
                  type='text'
                  placeholder={t('pages.companies.titleCard')}
                  value={cardRequestRegistration.title}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCardRequestRegistration({...cardRequestRegistration, title: event.target.value});
                  }}
                  className='outline-none focus:ring-black focus:ring-1 shadow-md p-4 rounded-lg bg-slate-50'
                  style={{resize: 'none'}}
                />
              </div>
              <div className='flex flex-col gap-1'>
                <span className='text-[18px]'>{t('pages.companies.textCard')}</span>
                <textarea
                  placeholder={t('pages.companies.textCard')}
                  value={cardRequestRegistration.text}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setCardRequestRegistration({...cardRequestRegistration, text: event.target.value});
                  }}
                  rows={3}
                  className='outline-none focus:ring-black focus:ring-1 shadow-md p-4 rounded-lg bg-slate-50'
                  style={{resize: 'none'}}
                />
              </div>
            </div>
            <div className='relative w-[250px]'>
              <ProgressBarMobile barColor={watch('appStyle.barReproductionColor')}
                                 primaryColor={watch('appStyle.primaryColor')}/>
              <div
                className='absolute top-0 left-0 h-full w-full z-[2] bg-black/50 backdrop-blur-sm flex justify-center items-center'>
                <div
                  className='w-[90%] py-6 px-4 rounded-xl text-center flex flex-col gap-4'
                  style={{background: watch('appStyle.secondaryColor'), color: watch('appStyle.textButtonColor')}}>
                  <h2 className='text-[20px]'>{cardRequestRegistration.title}</h2>
                  <p className='text-[14px]'>{cardRequestRegistration.text}</p>
                  <button
                    disabled
                    className='py-3 bg-red-200 rounded-full text-[18px] font-semibold'
                    style={{background: colorsButton.backgroundButton, color: colorsButton.textButton}}>
                    {t('pages.companies.enterFree')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='w-fit mx-auto pt-4'>
            <label className='grid grid-cols-4 my-5' htmlFor='appStyle.textButtonColor'>
              <p
                className='text-lg font-semibold col-span-2 leading-none self-center'>{t('pages.companies.backgroundButton')}</p>
              <input
                type='color'
                value={colorsButton.backgroundButton}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setColorsButtons({...colorsButton, backgroundButton: event.target.value});
                }}
                className='ml-3 self-center'
              />
              <p className='self-center'>{colorsButton.backgroundButton}</p>
            </label>
            <label className='grid grid-cols-4 my-5' htmlFor='appStyle.textButtonColor'>
              <p
                className='text-lg font-semibold col-span-2 leading-none self-center'>{t('pages.companies.textButton')}</p>
              <input
                type='color'
                value={colorsButton.textButton}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setColorsButtons({...colorsButton, textButton: event.target.value});
                }}
                className='ml-3 self-center'
              />
              <p className='self-center'>{colorsButton.textButton}</p>
            </label>
          </div>
        </div>
        <div className='m-10 p-10 rounded-3xl shadow-lg bg-functional-el-l'>
          <div className='flex justify-between items-center'>
            <div>
              <p
                className='font-bold text-xl text-text-functional-d-l my-2'>{t('pages.companies.videoConfiguration')}</p>
            </div>
          </div>
          <div className={cn('flex')}>
            <div className='w-full flex justify-around'>
              <div>
                <TitleInput>{t('pages.companies.startVideo')}</TitleInput>
                <p>{t('pages.companies.selectStartVideo')}</p>
                <div className='flex items-center justify-around'>
                  <div>
                    <div className={`relative my-2 h-[350px] w-[200px] bg-[#000000]/10`}>
                      {/* <span className="material-symbols-outlined absolute top-2 right-12 bg-primary-l-m p-2 mx-2 rounded text-functional-d-d">delete</span> */}
                      <span
                        onClick={handleInitialOpenModal}
                        className='cursor-pointer material-symbols-outlined absolute top-2 right-2 bg-primary-l-m p-2 rounded text-functional-d-d z-10'>
                                    settings
                                 </span>
                      {initialVideoSelected?.videoSelected?.link ? (
                        <video src={initialVideoSelected.videoSelected.link}
                               className={'w-full h-full object-contain'}/>
                      ) : (
                        <img src={'/imgLayoutMobile.png'} alt='' className='w-full h-full object-cover'/>
                      )}
                    </div>
                  </div>
                  <div className='ml-4'>
                    <div>
                      <InputText
                        disabled={disabled}
                        register={() => register('home_data.title')}
                        placeholder={t('generics.name')}
                      />
                    </div>
                    <div>
                      <TextArea
                        disabled={disabled}
                        register={() => register('home_data.description')}
                        placeholder={t('generics.description')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <TitleInput>{t('pages.companies.videoModules')}</TitleInput>
                <p>{t('pages.companies.videoModulesMessage')}</p>
                <div className='flex items-center justify-around'>
                  <div>
                    <div className={'relative my-2 h-[350px] w-[200px] bg-[#000000]/10'}>
                      {/* <span className="material-symbols-outlined absolute top-2 right-12 bg-primary-l-m p-2 mx-2 rounded text-functional-d-d">delete</span> */}
                      <span
                        onClick={handleModuleOpenModal}
                        className='material-symbols-outlined absolute top-2 right-2 bg-primary-l-m p-2 rounded text-functional-d-d z-10'>
                                    settings
                                 </span>
                      {moduleVideoSelected?.videoSelected?.link ? (
                        <video src={moduleVideoSelected.videoSelected.link} className={'w-full h-full object-contain'}/>
                      ) : (
                        <img src={'/imgLayoutMobile.png'} alt='' className='w-full h-full object-cover'/>
                      )}
                    </div>
                  </div>
                  <div className='ml-4'>
                    <div className=''>
                      <InputText
                        disabled={disabled}
                        register={() => register('modules_data.title')}
                        placeholder={t('generics.name')}
                      />
                    </div>
                    <div className=''>
                      <TextArea
                        disabled={disabled}
                        register={() => register('modules_data.description')}
                        placeholder={t('generics.description')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='m-10 p-10 rounded-3xl shadow-lg bg-functional-el-l flex justify-between items-start flex-wrap gap-x-[15px] gap-y-[20px]'>
          <div className='w-[400px]'>
            <p
              className='font-bold text-[20px] text-text-functional-d-l'>{t('pages.companies.pointsConfiguration')}</p>
            <div className='flex items-center gap-2'>
              <p className='text-[18px]'>{t('pages.companies.assignPoints')}</p>
              <ToggleSwitch disabled={disabled} message='' register={() => register('featureFlags.enablePoints')}/>
              {errors?.featureFlags?.enablePoints ? (
                <p className='text-warning-d'>{errors.featureFlags.enablePoints.message}</p>
              ) : null}
            </div>
          </div>
          <div className='w-[400px]'>
            <p className='font-bold text-[20px] text-text-functional-d-l'>{t('pages.companies.storeAccess')}</p>
            <div className='flex items-center gap-2'>
              <p className='text-[18px]'>{t('pages.companies.assignStore')}</p>
              <ToggleSwitch disabled={disabled} message='' register={() => register('featureFlags.enableStore')}/>
              {errors?.featureFlags?.enableStore ? (
                <p className='text-warning-d'>{errors.featureFlags.enableStore.message}</p>
              ) : null}
            </div>
          </div>
          <div className='w-[400px]'>
            <p className='font-bold text-[18px] text-text-functional-d-l'>Activar boton de cerrar</p>
            <div className='flex flex-col'>
              <div className='flex items-center gap-2'>
                <p className='text-[18px]'>¿Mostrar el boton de cerrar?</p>
                <ToggleSwitch
                  disabled={disabled}
                  message=''
                  register={() => register('featureFlags.redirectOnClose.showButton')}
                  closeButton={{initialValue: showInputClose, setValue: setShowInputClose}}
                />
              </div>
              {showInputClose && (
                <InputText
                  disabled={disabled}
                  register={() => register('featureFlags.redirectOnClose.redirectURL')}
                  placeholder='Ruta'
                />
              )}
            </div>
          </div>
          <div className='w-[400px]'>
            <p
              className='font-bold text-[18px] text-text-functional-d-l'>{t("pages.companies.enableUserRegistration")}</p>
            <div className='flex flex-col'>
              <div className='flex items-center gap-2'>
                <p className='text-[18px]'>{t("pages.companies.enableUserRegistrationMessage")}</p>
                <ToggleSwitch disabled={disabled} message=''
                              register={() => register('userInteraction.enableUserRegistration')}/>
              </div>
            </div>
          </div>
          <div className='w-[400px]'>
            <p
              className='font-bold text-[20px] text-text-functional-d-l'>{t('pages.companies.enableUserProfile')}</p>
            <div className='flex items-center gap-2'>
              <p className='text-[18px]'>{t('pages.companies.profileAccess')}</p>
              <ToggleSwitch disabled={disabled} message=''
                            register={() => register('userInteraction.enableUserProfile')}/>
              {errors?.userInteraction?.enableUserProfile ? (
                <p className='text-warning-d'>{errors.userInteraction.enableUserProfile.message}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div className='flex justify-end p-5'>
          <PrimaryButton disabled={crudDisabled} type='submit'>
            {t('pages.companies.customSave')}
          </PrimaryButton>
          <SecondaryButton className='px-10' disabled={crudDisabled} onClick={handleCancel}>
            {t('generics.goBack')}
          </SecondaryButton>
        </div>
      </form>
    </>
  );
};

export const PersonalizationEmpresas = () => {
  const {state} = useLocation();

  const {
    isLoading,
    company,
    status,
    crudDisabled,
    loaderImage,
    transitionImage,
    isOpen,
    setIsOpen,
    setInitialSelectVideo,
    setModuleSelectVideo,
    moduleVideoSelected,
    initialVideoSelected,
    viewing,
    editing,
    loadLoaderImageWithValidations,
    loadTransitionImageWithValidations,
    updateCompany
  } = usePersonalizationCompany(state.companyId);

  return (
    <>
      <TabCompany companyId={state?.companyId}/>
      {!isLoading ? (
        <article className='bg-functional-el-m'>
          <PersonalizationEmpresasForm
            {...{
              updateCompany,
              isOpen,
              setIsOpen,
              setInitialSelectVideo,
              setModuleSelectVideo,
              moduleVideoSelected,
              initialVideoSelected,
              transitionImage,
              loadTransitionImageWithValidations,
              status,
              configuration: company,
              loaderImage,
              loadLoaderImageWithValidations,
              crudDisabled,
              viewing,
              editing
            }}
          />
        </article>
      ) : (
        <Spinner/>
      )}
      {isOpen ? (
        <ChooseVideo
          {...{
            data: {companySlug: state.companyId},
            isOpen: !!isOpen,
            close: () => setIsOpen(undefined),
            save: (props) => {
              props.label === 'moduleModal' ? setModuleSelectVideo(props) : setInitialSelectVideo(props);
            },
            label: isOpen
          }}
        />
      ) : null}
    </>
  );
};
