import useAxiosPrivate from "@/hooks/useApiV2Private"
import { LoadLesson } from "@/pages/lessons/models"
import { Module } from "@/pages/usuarios/models/users.models"
import { AppStore } from "@/redux"
import { useState } from "react"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"
import { Comment, SocialResp } from "../interface/social.interface"
import { FILTER_STATUS } from "../constants/filters.social"


export const useSocial = () => {

  const apiV2 = useAxiosPrivate()
  const { companySlug } = useSelector((state: AppStore) => state.ui)
  const [isLoading, setIsLoading] = useState(false)
  const [comments, setComments] = useState<Comment[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [modules, setModules] = useState<Module[]>([])
  const [lessons, setLessons] = useState<LoadLesson[]>([])


  /**
   * The function `searchComments` is an asynchronous function that fetches comments based on specified
   * parameters and updates state variables accordingly.
   * @param {string} status - The `status` parameter in the `searchComments` function is used to
   * specify the status of the comments that you want to search for. It is a required parameter and
   * must be provided when calling the function.
   * @param [page=1] - The `page` parameter in the `searchComments` function is used to specify the
   * page number of comments to retrieve. By default, it is set to 1 if not provided. This parameter is
   * used for pagination, allowing users to navigate through different pages of comments.
   * @param {string} [module] - The `module` parameter in the `searchComments` function is an optional
   * parameter that represents a specific module related to the comments being searched for. If
   * provided, it will be included in the query parameters when making the API request to filter the
   * comments based on the specified module.
   * @param {string} [lessons] - The `lessons` parameter in the `searchComments` function is used to
   * filter comments based on the lessons associated with them. If a `lessons` parameter is provided,
   * it will be included in the query parameters when making the API request to fetch comments. This
   * allows you to retrieve comments that are related
   * @param {string} [cellphone] - The `cellphone` parameter in the `searchComments` function is an
   * optional parameter that represents the cellphone number associated with the comments being
   * searched for. If provided, it will be included in the query parameters sent to the API endpoint to
   * filter the search results based on the specified cellphone number. If not
   */
  const searchComments = async (status: string, page = 1, module?: string, lessons?: string, cellphone?: string) => {
    const query = new URLSearchParams()
    query.append('status', status)
    query.append('page', page.toString())
    if (module) query.append('module', module)
    if (lessons) query.append('lesson', lessons)
    if (cellphone) query.append('cellphone', cellphone)
    setComments([])
    setIsLoading(true)
    try {
      const res = await apiV2.get(`/companies/${companySlug}/comments?${query.toString()}`)
      const data: SocialResp = res.data
      setCurrentPage(data.pagination.current_page)
      setTotalPages(data.pagination.last_page)
      setComments(data.comments)
    }
    catch (error) {
      toast.error('Error cargando los comentarios')
      console.error('Error cargando los comentarios', error)
    }
    finally {
      setIsLoading(false)
    }
  }

  /**
   * The function `loadModules` asynchronously fetches modules data from an API endpoint and updates the
   * state with the retrieved data or displays an error message if there is an error.
   */
  const loadModules = async (): Promise<void> => {
    setModules([])
    try {
      const res = await apiV2.get(`/companies/${companySlug}/modules?per_page=-1`)
      const data = res.data.items
      setModules(data)
    }
    catch (err) {
      console.error('Error cargando los módulos', err)
      toast.error('Error cargando los módulos')
    }
  }

  /**
   * The function `loadLessons` asynchronously fetches lessons for a specific module and handles errors
   * accordingly.
   * @param {string} moduleSlug - The `moduleSlug` parameter in the `loadLessons` function is a string
   * that represents the unique identifier or slug of a module. It is used to fetch lessons associated
   * with that particular module from the API.
   */
  const loadLessons = async (moduleSlug: string): Promise<void> => {
    setLessons([])
    try {
      const res = await apiV2.get(`/companies/${companySlug}/modules/${moduleSlug}/lessons?per_page=-1`)
      const data = res.data.items
      setLessons(data)
    }
    catch (err) {
      console.error('Error cargando las lecciones', err)
      toast.error('Error cargando las lecciones')
    }
  }

  /**
   * The function `nextPage` asynchronously searches for comments on the next page based on the current
   * page number and provided parameters.
   * @param {string} status - The `status` parameter is a string that likely represents the current
   * status or state of something in the application or system. It could be used to determine the
   * conditions under which the `nextPage` function should be called.
   * @param {string} [module] - The `module` parameter in the `nextPage` function represents the module
   * of the lesson that the user is currently on. It is an optional parameter, meaning it is not
   * required to be provided when calling the function. If provided, it specifies the module of the
   * next page to be navigated to
   * @param {string} [lesson] - The `lesson` parameter in the `nextPage` function represents the
   * specific lesson within a module that the user is currently on. It is an optional parameter,
   * meaning it is not required to be provided when calling the function. If provided, it helps in
   * navigating to the next page by specifying the lesson
   * @param {string} [cellphone] - The `cellphone` parameter in the `nextPage` function is an optional
   * parameter of type string. It is used to pass the cellphone number as an argument when calling the
   * `searchComments` function.
   */
  const nextPage = async (status: string, module?: string, lesson?: string, cellphone?: string) => {
    if (currentPage < totalPages) {
      await searchComments(status, currentPage + 1, module, lesson, cellphone)
    }
  }

  const previousPage = async (status: string, module?: string, lesson?: string, cellphone?: string) => {
    if (currentPage > 1) {
      await searchComments(status, currentPage - 1, module, lesson, cellphone)
    }
  }

  const firstPage = async (status: string, module?: string, lesson?: string, cellphone?: string) => {
    await searchComments(status, 1, module, lesson, cellphone)
  }

  const lastPage = async (status: string, module?: string, lesson?: string, cellphone?: string) => {
    await searchComments(status, totalPages, module, lesson, cellphone)
  }

  /**
   * The `approveComment` function asynchronously approves a comment using an API call and updates the
   * comment status in the state accordingly.
   * @param {number} commentId - The `commentId` parameter is a number that represents the unique
   * identifier of the comment that you want to approve.
   */
  const approveComment = async (commentId: number) => {
    try {
      await apiV2.put(`/companies/${companySlug}/comments/${commentId}/approve`)
      toast.success('Comentario aprobado')
      setComments(comments.map(comment => {
        if (comment.id === commentId) {
          return { ...comment, status: FILTER_STATUS.approved }
        }
        return comment
      }))
    }
    catch (err) {
      console.error('Error aprobando el comentario', err)
      toast.error('Error aprobando el comentario')
    }
  }

  /**
   * The `rejectComment` function asynchronously rejects a comment by updating its status and displaying
   * a success or error message.
   * @param {number} commentId - The `commentId` parameter is a number that represents the unique
   * identifier of the comment that you want to reject.
   * @param {string} [reason] - The `reason` parameter in the `rejectComment` function is an optional
   * parameter of type string. It allows the user to provide a reason for rejecting a comment, but it is
   * not required for the function to work. If a reason is provided, it can be used for logging or
   * displaying additional information
   */
  const rejectComment = async (commentId: number, reason?: string) => {
    try {
      await apiV2.put(`/companies/${companySlug}/comments/${commentId}/reject`, {
        rejection_reason: reason
      })
      toast.success('Comentario rechazado')
      setComments(comments.map(comment => {
        if (comment.id === commentId) {
          return { ...comment, status: FILTER_STATUS.rejected, rejection_reason: reason ?? null }
        }
        return comment
      }))
    }
    catch (err) {
      console.error('Error rechazando el comentario', err)
      toast.error('Error rechazando el comentario')
    }
  }

  return {
    isLoading,
    searchComments,
    comments,
    currentPage,
    totalPages,
    nextPage,
    previousPage,
    firstPage,
    lastPage,
    loadLessons,
    loadModules,
    modules,
    lessons,
    approveComment,
    rejectComment
  }
}