import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdAutorenew
} from "react-icons/md";
import { Loader } from "../dashboard/components/Loader";
import { REPORT_ORDER, LESSONS_REPORT_FILTERS } from "./interfaces/reports";
import { useLessonsReport } from "./hooks/useLessonsReport";
import { useLoadCampanies } from "@/hooks";
import { Select } from "@/components";

export const LessonsReportsPage = (): JSX.Element => {
  const {
    isLoading,
    currentPage,
    totalPages,
    lessonsReports,
    nextPage,
    previousPage,
    lastPage,
    firstPage,
    loadLessonsReports,
    isDownloadingReport,
    downloadReport,
  } = useLessonsReport();
  const [search, setSearch] = useState<string>("");
  const [sortType, setSortType] = useState<
    LESSONS_REPORT_FILTERS | undefined
  >();
  const [sortOrder, setSortOrder] = useState<REPORT_ORDER | undefined>();
  const [companySlug, setCompanySlug] = useState<string | undefined>();
  const { loadCompanies, companies } = useLoadCampanies();

  useEffect(() => {
    setSearch("");
    setSortType(undefined);
    setSortOrder(undefined);
    if (companySlug) {
      loadLessonsReports(1, "", undefined, undefined, companySlug);
    }
  }, [companySlug]);

  useEffect(() => {
    loadCompanies();
  }, []);

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    debouncedSearch(value);
  };

  const handleSort = (type: LESSONS_REPORT_FILTERS) => {
    if (isLoading) {
      return;
    }
    let order;
    if (sortType === type) {
      if (sortOrder === REPORT_ORDER.DESC) {
        setSortType(undefined);
        setSortOrder(undefined);
        loadLessonsReports(1, search, sortType, sortOrder, companySlug);
        return;
      }
      order =
        sortOrder === REPORT_ORDER.ASC ? REPORT_ORDER.DESC : REPORT_ORDER.ASC;
      setSortOrder(order);
    } else {
      setSortType(type);
      order = REPORT_ORDER.ASC;
      setSortOrder(order);
    }
    loadLessonsReports(1, search, type, order, companySlug);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      loadLessonsReports(1, query, sortType, sortOrder, companySlug);
    }, 300),
    [companySlug, sortType, sortOrder]
  );

  return (
    <>
      <div className="w-full h-auto">
        <div className="w-full rounded-lg h-auto p-4 pb-2 bg-white flex justify-between gap-x-[30px]">
          <div className="w-full justify-between items-center">
            <h2 className="text-[30px] font-semibold text-start text-black">
              {t("pages.reports.lessonsReports")}
            </h2>
            <p className="text-start">{t("pages.reports.availableReports")}</p>
          </div>
          <Select
            onChange={(e) => setCompanySlug(e.target.value)}
            defaultValue={"default"}
            className="w-auto"
            options={companies}
            optionmap={(value) => value.name}
            optionValueMap={(value) => value.slug}
            optionKeyMap={(value) => value.id}
            value={companySlug || "default"}
          />
        </div>
        {companySlug && (
          <div className="w-full h-auto p-4 mt-4 rounded-lg bg-[#f6f9fe] min-h-[calc(100vh-180px)] overflow-hidden">
            <div className="w-full flex justify-end items-center gap-4 flex-wrap mb-[30px]">
              {/* Input search */}
              <input
                type="text"
                placeholder={t("generics.search")}
                className="w-[200px] h-[40px] rounded-lg border border-gray-300 p-2 bg-[#eaeaea]"
                onChange={handleChangeSearch}
                value={search}
              />
              {/* Button Download Xsls*/}
              <button
                className="px-4 py-2 rounded-lg bg-[#000] text-white cursor-pointer w-[170px] flex items-center justify-center"
                onClick={() => downloadReport(companySlug)}
              >
                {isDownloadingReport ? (
                  <MdAutorenew className="animate-spin text-[20px]" />
                ) : (
                  <>{t("generics.download")} XLSX</>
                )}
              </button>
            </div>
            {/* Table */}
            <div className="w-full h-auto bg-white rounded-lg p-4 shadow-md overflow-x-auto">
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <div className="relative h-auto min-h-[300px] w-full rounded-lg overflow-hidden bg-white overflow-y-auto p-4 min-w-[1400px]">
                    <div className="sticky w-full flex justify-between top-0 bg-[#ededed] z-[2] rounded-xl mb-[10px]">
                      <div className={`${headerCell} min-w-[150px]`}>Id</div>
                      <div
                        className={`${headerCell} w-full max-w-[250px] min-w-[250px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(LESSONS_REPORT_FILTERS.LESSON)
                        }
                      >
                        {sortType === LESSONS_REPORT_FILTERS.LESSON && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.lesson")}
                      </div>
                      <div
                        className={`${headerCell} w-full max-w-[170px] min-w-[170px] hover:bg-[#dedede] transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() => handleSort(LESSONS_REPORT_FILTERS.STATE)}
                      >
                        {sortType === LESSONS_REPORT_FILTERS.STATE && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.state")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[250px] min-w-[250px] hover:bg-[#dedede] transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(LESSONS_REPORT_FILTERS.MODULE)
                        }
                      >
                        {sortType === LESSONS_REPORT_FILTERS.MODULE && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.module")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[100px] min-w-[100px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() => handleSort(LESSONS_REPORT_FILTERS.VIEWS)}
                      >
                        {sortType === LESSONS_REPORT_FILTERS.VIEWS && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.views")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[120px] min-w-[120px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(LESSONS_REPORT_FILTERS.QUIZZES)
                        }
                      >
                        {sortType === LESSONS_REPORT_FILTERS.QUIZZES && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.quizzes")}
                      </div>
                      {/*  <div
                      className={`${headerCell} max-w-[130px] min-w-[130px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                        isLoading ? "cursor-not-allowed" : "cursor-pointer"
                      }`}
                      onClick={() => handleSort(LESSONS_REPORT_FILTERS.CORRECT)}
                    >
                      {sortType === LESSONS_REPORT_FILTERS.CORRECT && (
                        <span className="mr-1">
                          {sortOrder === REPORT_ORDER.ASC ? (
                            <MdArrowUpward />
                          ) : (
                            <MdArrowDownward />
                          )}
                        </span>
                      )}
                      {t("pages.reports.correct")}
                    </div> */}
                      <div
                        className={`${headerCell} max-w-[100px] min-w-[100px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() => handleSort(LESSONS_REPORT_FILTERS.TASKS)}
                      >
                        {sortType === LESSONS_REPORT_FILTERS.TASKS && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.tasks")}
                      </div>
                    </div>
                    {lessonsReports.map((lessonReport, index) => (
                      <>
                        <div
                          className={`w-full flex justify-between border-b border-gray-200 ${
                            index % 2 === 0
                              ? "bg-white hover:bg-gray-50"
                              : "bg-slate-100 hover:bg-slate-200"
                          }`}
                          key={lessonReport.id}
                        >
                          <div className={`${bodycell} min-w-[150px]`}>
                            {lessonReport.id}
                          </div>
                          <div
                            className={`${bodycell} w-full max-w-[250px] min-w-[250px]`}
                          >
                            {lessonReport.name ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} w-full max-w-[170px] min-w-[170px]`}
                          >
                            {lessonReport.state ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[250px] min-w-[250px]`}
                          >
                            {lessonReport.module ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[100px] min-w-[100px]`}
                          >
                            {lessonReport.views ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[120px] min-w-[120px]`}
                          >
                            {lessonReport.quizzes ?? "-"}
                          </div>
                          {/* <div
                          className={`${bodycell} max-w-[130px] min-w-[130px]`}
                        >
                          {lessonReport.correct_answers}
                        </div> */}
                          <div
                            className={`${bodycell} max-w-[100px] min-w-[100px]`}
                          >
                            {lessonReport.tasks ?? "-"}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="flex justify-center mt-4">
                    {currentPage > 1 && (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            firstPage(search, sortType, sortOrder, companySlug);
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            first_page
                          </span>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            previousPage(search, sortType, sortOrder, companySlug);
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            keyboard_arrow_left
                          </span>
                        </button>
                      </>
                    )}
                    <p className="p-2">
                      {currentPage}-{totalPages}
                    </p>
                    {currentPage < totalPages && (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            nextPage(search, sortType, sortOrder, companySlug);
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            keyboard_arrow_right
                          </span>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            lastPage(search, sortType, sortOrder, companySlug);
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            last_page
                          </span>
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const headerCell =
  "text-left min-h-[50px] px-4 font-semibold flex items-center text-sm";
const bodycell =
  "relative text-left min-h-[50px] px-4 flex items-center word-break";
