import { t } from "i18next";
import { useEffect, useState, useRef } from "react";
import { MdArrowUpward, MdArrowDownward, MdAutorenew } from "react-icons/md";
import { useLogs } from "../hooks/useLogs";
import { LOGS_FILTERS, LOGS_ORDER } from "../interfaces/logs";

export const UserLog = ({userId}: {userId: number}) => {
  const {
    currentPage,
    totalPages,
    nextPage,
    previousPage,
    firstPage,
    lastPage,
    loadLogs,
    logs,
    intervalIdRef,
    isDownloadingLogs,
    downloadLogs,
  } = useLogs(userId);

  const [sortType, setSortType] = useState<LOGS_FILTERS | undefined>();
  const [sortOrder, setSortOrder] = useState<LOGS_ORDER | undefined>();

  const sortTypeRef = useRef(sortType);
  const sortOrderRef = useRef(sortOrder);

  useEffect(() => {
    sortTypeRef.current = sortType;
    sortOrderRef.current = sortOrder;
  }, [sortType, sortOrder]);

  useEffect(() => {
    return () => {
      if (intervalIdRef.current !== undefined) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (currentPage === 1) {
      startListening();
    } else {
      if (intervalIdRef.current !== undefined) {
        clearInterval(intervalIdRef.current);
      }
    }
  }, [currentPage]);

  const startListening = async () => {
    await loadLogs(1, sortTypeRef.current, sortOrderRef.current).catch(
      console.error
    );
    const id = window.setInterval(() => {
      loadLogs(1, sortTypeRef.current, sortOrderRef.current).catch(
        console.error
      );
    }, 300000);
    intervalIdRef.current = id;
  };

  const handleSort = (type: LOGS_FILTERS) => {
    let order;
    if (sortType === type) {
      order = sortOrder === LOGS_ORDER.ASC ? LOGS_ORDER.DESC : LOGS_ORDER.ASC;
      setSortOrder(order);
    } else {
      setSortType(type);
      order = LOGS_ORDER.ASC;
      setSortOrder(order);
    }
    loadLogs(1, type, order);
  };

  return (
    <>
      {/* user Logs */}
      <h3 className="text-2xl font-bold text-functional-d-l px-10 text-black">
        {t("pages.users.userLog")}
      </h3>
      <p className="text-lg font-regular text-functional-d-l px-10 text-[#878787]">
        {t("pages.users.userLogMessage")}
      </p>
      {/* Table */}
      <div className="mx-11 my-3 bg-functional-el-l rounded-3xl shadow-lg">
        <div className="relative h-auto min-h-[300px] w-full rounded-lg overflow-hidden bg-white overflow-y-auto shadow-md mt-[20px] p-4">
          <div className="sticky w-full flex justify-between top-0 bg-[#ededed] z-[2] rounded-3xl mb-[10px]">
            <div className={`${headerCell} min-w-[70px]`}>Id</div>
            <div
              className={`${headerCell} w-full max-w-[250px] hover:bg-[#dedede] cursor-pointer transition-all`}
              onClick={() => handleSort(LOGS_FILTERS.DATE_AND_HOUR)}
            >
              {t("pages.users.dateAndHour")}
              {sortType === LOGS_FILTERS.DATE_AND_HOUR && (
                <span className="ml-1">
                  {sortOrder === LOGS_ORDER.ASC ? (
                    <MdArrowUpward />
                  ) : (
                    <MdArrowDownward />
                  )}
                </span>
              )}
            </div>
            <div
              className={`${headerCell} min-w-[250px] max-w-[250px] hover:bg-[#dedede] cursor-pointer transition-all`}
              onClick={() => handleSort(LOGS_FILTERS.TYPE)}
            >
              {t("pages.users.type")}
              {sortType === LOGS_FILTERS.TYPE && (
                <span className="ml-1">
                  {sortOrder === LOGS_ORDER.ASC ? (
                    <MdArrowUpward />
                  ) : (
                    <MdArrowDownward />
                  )}
                </span>
              )}
            </div>
            <div
              className={`${headerCell} w-full overflow-x-auto overflow-hidden`}
            >
              {t("pages.users.description")}
            </div>
          </div>
          {/* Loop Logs */}
          {logs && Array.isArray(logs) && logs.length > 0 && (
            <>
              {logs.map((log, index) => (
                <div
                  className={`w-full flex justify-between bg-white border-b border-gray-200
                    ${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
                  key={log.id}
                >
                  <div className={`${bodycell} min-w-[70px]`}>{log.id}</div>
                  <div className={`${bodycell} w-full max-w-[250px]`}>
                    {log.created_at_client}
                  </div>
                  <div className={`${bodycell} min-w-[250px] max-w-[250px]`}>
                    {log.type}
                  </div>
                  <div
                    className={`relative text-left min-h-[50px] px-4 flex items-center w-full overflow-x-auto overflow-hidden`}
                  >
                    {log.detail}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div className="flex justify-center mt-4 mb-4">
        {currentPage > 1 && (
          <>
            <button
              type="button"
              onClick={() => {
                firstPage(sortType, sortOrder);
              }}
            >
              <span className="material-symbols-outlined cursor-pointer text-3xl">
                first_page
              </span>
            </button>
            <button
              type="button"
              onClick={() => {
                previousPage(sortType, sortOrder);
              }}
            >
              <span className="material-symbols-outlined cursor-pointer text-3xl">
                keyboard_arrow_left
              </span>
            </button>
          </>
        )}
        <p className="p-2">
          {currentPage}-{totalPages}
        </p>
        {currentPage < totalPages && (
          <>
            <button
              type="button"
              onClick={() => {
                nextPage(sortType, sortOrder);
              }}
            >
              <span className="material-symbols-outlined cursor-pointer text-3xl">
                keyboard_arrow_right
              </span>
            </button>
            <button
              type="button"
              onClick={() => {
                lastPage(sortType, sortOrder);
              }}
            >
              <span className="material-symbols-outlined cursor-pointer text-3xl">
                last_page
              </span>
            </button>
          </>
        )}
      </div>
      {/* Button Download logs */}
      <div className="flex justify-center pb-[40px]">
        <button
          type="button"
          onClick={() => downloadLogs(userId)}
          className="bg-functional-d-l text-white text-base px-6 py-2 rounded-lg w-[200px] flex justify-center items-center h-[45px] hover:bg-functional-d-d transition-all"
        >
          {isDownloadingLogs ? (
            <MdAutorenew className="animate-spin text-xl" />
          ) : (
            t("logs.downloadLogs")
          )}
        </button>
      </div>
    </>
  );
};

const headerCell =
  "text-left min-h-[50px] px-4 font-semibold flex items-center truncate";
const bodycell =
  "relative text-left min-h-[50px] px-4 flex items-center truncate";
