export const FILTER_STATUS = {
  all: 'all',
  approved: 'approved',
  pending: 'pending',
  rejected: 'rejected'
}

export const FILTER_STATUS_OPTIONS = [
  {
    label: 'pages.social.all',
    value: 'all'
  },
  {
    label: 'pages.social.approved',
    value: 'approved'
  },
  {
    label: 'pages.social.pending',
    value: 'pending'
  },
  {
    label: 'pages.social.rejected',
    value: 'rejected'
  }
]