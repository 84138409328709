import { apiV3 } from "@/apis/apiV3";
import { useEffect } from "react";
import { useAuthStore } from "@/pages/auth/hooks";
import toast from "react-hot-toast";

const useAxiosPrivate = () => {

    const { checkAuthToken, user } = useAuthStore()

    useEffect(() => {
        const responseIntercept = apiV3.interceptors.response.use(
            response => response,
            async (error) => {
                console.log({ error })
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    await checkAuthToken();
                    return apiV3(prevRequest);
                }
                if (error.response?.status === 500) {
                    toast.error('Error en el servidor, informe al área de desarrollo')
                }
                if (error.code !== "ERR_CANCELED") return Promise.reject(error)
            }
        );

        return () => {
            apiV3.interceptors.response.eject(responseIntercept);
        }
    }, [user, checkAuthToken])

    return apiV3;
}

export default useAxiosPrivate;