import { Pagination } from "@/models/pagination.models";

export enum USERS_REPORT_FILTERS {
  FULL_NAME = 'full_name',
  MODULES_SEEN = 'module_count',
  LESSONS_SEEN = 'lessons_seen',
  LESSONS_COMPLETED = 'lessons_complete',
  LAST_LOGIN = 'last_access',
  REGISTER = 'created_at',
  POINTS = 'points',
  REWARDS = 'rewards',
}

export enum LESSONS_REPORT_FILTERS {
  LESSON = 'name',
  STATE = 'state',
  MODULE = 'module',
  VIEWS = 'views',
  QUIZZES = 'quizzes',
  CORRECT = 'correct_answers',
  TASKS = 'tasks',
}

export enum INTERACTIONS_REPORT_FILTERS {
  LESSON = 'lesson',
  TITLE = 'name',
  TYPE = 'type',
  COMPLETED = 'completed',
  STATE = 'status'
}

export enum REPORT_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export enum INTERACTION_TYPES {
  TASK = 'Tarea',
  QUIZ = 'Quiz',
  SURVEY = 'Encuesta',
}

export interface UsersReportResponse {
  pagination: Pagination
  items: UserReport[]
}

export interface UserReport {
  user_id: number
  created_at: string
  full_name: string
  last_access: string
  lessons_seen: number
  module_count: number
  points: number
  lesson_complete: number
  rewards: number
}

export interface LessonsReportResponse {
  pagination: Pagination
  items: LessonReport[]
}

export interface LessonReport {
  id: number
  name: string
  state: string
  module: string
  views: number
  quizzes: number
  correct_answers: number
  tasks: number
}

export interface InteractionsReportResponse {
  pagination: Pagination
  items: InteractionReport[]
}

export interface InteractionReport {
  id: number
  lesson: string
  name: string
  status: string
  type: string
  completed: number
  slug: string
  area_id: number
}
