import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdAutorenew,
  MdRemoveRedEye
} from "react-icons/md";
import { Loader } from "../dashboard/components/Loader";
import { useUsersReport } from "./hooks/useUsersReport";
import { REPORT_ORDER, UserReport, USERS_REPORT_FILTERS } from "./interfaces/reports";
import { Select } from "@/components";
import { useLoadCampanies } from "@/hooks";
import { useNavigate } from "react-router-dom";

export const UsersReportsPage = (): JSX.Element => {
  const {
    isLoading,
    currentPage,
    totalPages,
    usersReports,
    nextPage,
    previousPage,
    lastPage,
    firstPage,
    loadUsersReports,
    isDownloadingReport,
    downloadReport,
  } = useUsersReport();
  const [search, setSearch] = useState<string>("");
  const [sortType, setSortType] = useState<USERS_REPORT_FILTERS | undefined>();
  const [sortOrder, setSortOrder] = useState<REPORT_ORDER | undefined>();
  const [companySlug, setCompanySlug] = useState<string | undefined>();
  const { loadCompanies, companies } = useLoadCampanies();
  const navigate = useNavigate();

  useEffect(() => {
    setSearch("");
    setSortType(undefined);
    setSortOrder(undefined);
    if (companySlug) {
      loadUsersReports(1, "", undefined, undefined, companySlug);
    }
  }, [companySlug]);

  useEffect(() => {
    loadCompanies();
  }, []);

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    debouncedSearch(value);
  };

  const handleSort = (type: USERS_REPORT_FILTERS) => {
    if (isLoading) {
      return;
    }
    let order;
    if (sortType === type) {
      if (sortOrder === REPORT_ORDER.DESC) {
        setSortType(undefined);
        setSortOrder(undefined);
        loadUsersReports(1, search, sortType, sortOrder, companySlug);
        return;
      }
      order =
        sortOrder === REPORT_ORDER.ASC ? REPORT_ORDER.DESC : REPORT_ORDER.ASC;
      setSortOrder(order);
    } else {
      setSortType(type);
      order = REPORT_ORDER.ASC;
      setSortOrder(order);
    }
    loadUsersReports(1, search, type, order, companySlug);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      loadUsersReports(1, query, sortType, sortOrder, companySlug);
    }, 300),
    [companySlug, sortType, sortOrder]
  );

  /**
   * Navigates to the user edit page for a given user report
   * @param userReport - The user report object containing the user ID to navigate to
   * @remarks
   * - Encrypts the user ID using base64 encoding before adding to URL
   * - Navigates to the user CRUD page with the encrypted ID as a URL parameter
   */
  const goToUser = (userReport: UserReport) => {
    const encryptedId = btoa(userReport.user_id.toString());
    navigate(`/usuarios/crud?userId=${encryptedId}&fromReport=true`);
  };

  return (
    <>
      <div className="w-full h-auto">
        <div className="w-full rounded-lg h-auto p-4 pb-2 bg-white flex justify-between gap-x-[30px] items-center">
          <div className="w-full justify-between items-center">
            <h2 className="text-[30px] font-semibold text-start text-black">
              {t("pages.reports.usersReports")}
            </h2>
            <p className="text-start">{t("pages.reports.availableReports")}</p>
          </div>
          <Select
            onChange={(e) => setCompanySlug(e.target.value)}
            defaultValue={"default"}
            className="w-auto"
            options={companies}
            optionmap={(value) => value.name}
            optionValueMap={(value) => value.slug}
            optionKeyMap={(value) => value.id}
            value={companySlug || "default"}
          />
        </div>
        {companySlug && (
          <div className="w-full h-auto p-4 mt-4 rounded-lg bg-[#f6f9fe] min-h-[calc(100vh-180px)] overflow-hidden">
            <div className="w-full flex justify-end items-center gap-4 flex-wrap mb-[30px]">
              {/* Input search */}
              <input
                type="text"
                placeholder={t("generics.search")}
                className="w-[200px] h-[40px] rounded-lg border border-gray-300 p-2 bg-[#eaeaea]"
                onChange={handleChangeSearch}
                value={search}
              />
              {/* Button Download Xsls*/}
              <button
                className="px-4 py-2 rounded-lg bg-[#000] text-white cursor-pointer w-[170px] flex items-center justify-center"
                onClick={() => downloadReport(companySlug)}
              >
                {isDownloadingReport ? (
                  <MdAutorenew className="animate-spin text-[20px]" />
                ) : (
                  <>{t("generics.download")} XLSX</>
                )}
              </button>
            </div>
            {/* Table */}
            <div className="w-full h-auto bg-white rounded-lg p-4 shadow-md overflow-x-auto">
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <div className="relative h-auto min-h-[300px] w-full rounded-lg overflow-hidden bg-white overflow-y-auto p-4 min-w-[2000px]">
                    <div className="sticky w-full flex justify-between top-0 bg-[#ededed] z-[2] rounded-xl mb-[10px]">
                      <div className={`${headerCell} min-w-[150px]`}>Id</div>
                      <div
                        className={`${headerCell} w-full max-w-[250px] min-w-[250px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(USERS_REPORT_FILTERS.FULL_NAME)
                        }
                      >
                        {sortType === USERS_REPORT_FILTERS.FULL_NAME && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.fullName")}
                      </div>
                      <div
                        className={`${headerCell} w-full max-w-[170px] min-w-[170px] hover:bg-[#dedede] transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(USERS_REPORT_FILTERS.MODULES_SEEN)
                        }
                      >
                        {sortType === USERS_REPORT_FILTERS.MODULES_SEEN && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.modulesSeen")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[170px] min-w-[170px] hover:bg-[#dedede] transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(USERS_REPORT_FILTERS.LESSONS_SEEN)
                        }
                      >
                        {sortType === USERS_REPORT_FILTERS.LESSONS_SEEN && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.lessonsSeen")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[220px] min-w-[220px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(USERS_REPORT_FILTERS.LESSONS_COMPLETED)
                        }
                      >
                        {sortType ===
                          USERS_REPORT_FILTERS.LESSONS_COMPLETED && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.lessonsCompleted")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[200px] min-w-[200px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(USERS_REPORT_FILTERS.LAST_LOGIN)
                        }
                      >
                        {sortType === USERS_REPORT_FILTERS.LAST_LOGIN && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.lastLogin")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[200px] min-w-[200px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(USERS_REPORT_FILTERS.REGISTER)
                        }
                      >
                        {sortType === USERS_REPORT_FILTERS.REGISTER && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.register")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[120px] min-w-[120px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() => handleSort(USERS_REPORT_FILTERS.POINTS)}
                      >
                        {sortType === USERS_REPORT_FILTERS.POINTS && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.points")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[150px] min-w-[150px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() => handleSort(USERS_REPORT_FILTERS.REWARDS)}
                      >
                        {sortType === USERS_REPORT_FILTERS.REWARDS && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.rewards")}
                      </div>
                      <div
                        className={`${headerCell} min-w-[80px] max-w-[80px]`}
                      ></div>
                    </div>
                    {usersReports.map((userReport, index) => (
                      <>
                        <div
                          className={`w-full flex justify-between border-b border-gray-200 ${
                            index % 2 === 0
                              ? "bg-white hover:bg-gray-50"
                              : "bg-slate-100 hover:bg-slate-200"
                          }`}
                          key={userReport.user_id}
                        >
                          <div className={`${bodycell} min-w-[150px]`}>
                            {userReport.user_id ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} w-full max-w-[250px] min-w-[250px] overflow-hidden text-overflow-ellipsis whitespace-nowrap`}
                          >
                            {userReport.full_name ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} w-full max-w-[170px] min-w-[170px]`}
                          >
                            {userReport.module_count ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[170px] min-w-[170px]`}
                          >
                            {userReport.lessons_seen ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[220px] min-w-[220px]`}
                          >
                            {userReport.lesson_complete ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[200px] min-w-[200px]`}
                          >
                            {userReport.last_access ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[200px] min-w-[200px]`}
                          >
                            {userReport.created_at ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[120px] min-w-[120px]`}
                          >
                            {userReport.points ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[150px] min-w-[150px]`}
                          >
                            {userReport.rewards ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} min-w-[80px] max-w-[80px]`}
                          >
                            <button
                              onClick={() => goToUser(userReport)}
                              className={`${buttonAction} text-white hover:bg-primary-l-d`}
                              title="Editar"
                              type="button"
                            >
                              <MdRemoveRedEye />
                            </button>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="flex justify-center mt-4">
                    {currentPage > 1 && (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            firstPage(search, sortType, sortOrder, companySlug);
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            first_page
                          </span>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            previousPage(
                              search,
                              sortType,
                              sortOrder,
                              companySlug
                            );
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            keyboard_arrow_left
                          </span>
                        </button>
                      </>
                    )}
                    <p className="p-2">
                      {currentPage}-{totalPages}
                    </p>
                    {currentPage < totalPages && (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            nextPage(search, sortType, sortOrder, companySlug);
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            keyboard_arrow_right
                          </span>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            lastPage(search, sortType, sortOrder, companySlug);
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            last_page
                          </span>
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const headerCell =
  "text-left min-h-[50px] px-4 font-semibold flex items-center text-sm";
const buttonAction = "bg-primary-l-m text-[20px] p-2 rounded-lg right-0 mr-2";
const bodycell =
  "relative text-left min-h-[50px] px-4 flex items-center truncate";
