import axios, { InternalAxiosRequestConfig } from "axios"

import { getEnvVariables } from "@/utilities"
import { loadFromLocalStore, loadFromSessionStore } from "@/utilities/browser-storage"

const { VITE_API_V3_URL, VITE_PUBLIC_KEY } = getEnvVariables()

export const apiV3 = axios.create({
    baseURL: VITE_API_V3_URL,
    headers: {
        Accept: 'application/json',
        'Public-Key': VITE_PUBLIC_KEY,
        "Content-type": "application/json"
    }
})

apiV3.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.headers = config.headers ?? {}
    let userToken = ''
    if (localStorage.token) {
        userToken = loadFromLocalStore('token')
    } else {
        userToken = loadFromSessionStore('token')
    }
    config.headers['Authorization'] = userToken ? `Bearer ${userToken}` : null
    config.headers['Content-type'] = "application/json"
    return config
})

export const apiV3Files = axios.create({
    baseURL: VITE_API_V3_URL + `/files`,
    headers: {
        Accept: 'application/json',
        'Public-Key': VITE_PUBLIC_KEY,
        "Content-type": "multipart/form-data"
    }
})

apiV3Files.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.headers = config.headers ?? {}
    let userToken = ''
    if (localStorage.token) {
        userToken = loadFromLocalStore('token')
    } else {
        userToken = loadFromSessionStore('token')
    }
    config.headers['Authorization'] = userToken ? `Bearer ${userToken}` : null
    config.headers['Content-type'] = "multipart/form-data"
    return config
})
