import { PrimaryButton, Select, SecondaryButton } from "@/components";
import { Input } from "@/components/Inputs/InputPhone";
import { InputText } from "@/components/Inputs/InputText";
import { TitleInput } from "@/components/Inputs/TitleInput";
import { loadImageWithValidationsProps, SetImageProps } from "@/hooks";
import { Areas, Area } from "@/models/areas.models";
import { Companies, CountryPhone } from "@/models/companies.model";
import { Roles, Rol } from "@/models/roles.models";
import { t } from "i18next";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { IoMdClose } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
import { UserLog } from "./UserLog";
import cn from "classnames";

const defaultValuesAdapter = (user: any): any => {
  return {
    name: user.name,
    email: user.email,
    phone: user.phone,
    code: user.code,
  };
};

interface UsuariosFormProp {
  image: any;
  user: any;
  status: string;
  updateUser: (data: any) => Promise<
    | {
        message: string;
      }
    | undefined
  >;
  editing: () => void;
  viewing: () => void;
  createUser: (data: any) => Promise<
    | {
        message: string;
      }
    | undefined
  >;
  loadImageWithValidations: loadImageWithValidationsProps;
  setImage: React.Dispatch<React.SetStateAction<SetImageProps | undefined>>;
  companies: Companies;
  roles: Roles;
  areas: Areas;
  countries: CountryPhone[];
}

interface UserProps {
  name: string;
  email: string;
  phone: string;
  image: any;
  password?: string;
  country?: string;
  area?: string;
  company?: string;
  code?: string;
}

export const UsuarioForm: React.FC<UsuariosFormProp> = ({
  countries,
  loadImageWithValidations,
  image,
  setImage,
  user,
  status,
  editing,
  viewing,
  createUser,
  updateUser,
  areas,
  roles,
}) => {
  const infoStatus: any = {
    viewing: {
      title: t("pages.users.userInfo"),
      message: "",
    },
    editing: {
      title: t("pages.users.modifyUser"),
      message: t("pages.users.crudMessage"),
    },
    creating: {
      title: t("pages.users.addUser"),
      message: t("pages.users.crudMessage"),
    },
  };

  const navigate = useNavigate();
  const [disabledButton, setDisabledButton] = useState(false);
  const [optionsRol, setOptionRol] = useState<string[]>([]);
  const [defaultArea, setDefaultArea] = useState<number | null>();
  const [optionsArea, setOptionArea] = useState<string[]>([]);
  const [rolesMapId, setRolesMapId] = useState<any>({});
  // const areaMapId = areas?.reduce((acc: any, cur: Area) => ({ ...acc, [cur.id]: cur }), {}) || {};
  const isCreating = status === "creating";
  const isEditing = status === "editing";
  const disabled = status === "viewing";

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserProps>({
    defaultValues: { ...defaultValuesAdapter(user) },
  });

  useEffect(() => {
    if (!user) return;
    reset({ ...defaultValuesAdapter(user) });
    setDefaultArea(user.default_area_id ?? null);
    setOptionArea(
      user?.company_areas?.map(({ id }: { id: number }) => id.toString()) || []
    );
    setOptionRol(user?.roles?.map(({ id }: { id: number }) => id.toString()) || []);
  }, [user]);

  useEffect(() => {
    setRolesMapId(roles?.reduce((acc: any, cur: Rol) => ({ ...acc, [cur.id]: cur }), {}) || {})
  }, [roles]);

  const onSubmit = async (data: any) => {
    if (!defaultArea) {
      toast.error(t("pages.users.defaultAreaError"));
      return;
    }
    setDisabledButton(true);
    if (
      status === "editing" &&
      optionsRol.length > 0 &&
      optionsArea.length > 0
    ) {
      await updateUser({
        data,
        roles: optionsRol,
        areas: optionsArea,
        default_area_id: defaultArea,
      });
    } else if (isCreating && optionsRol.length > 0 && optionsArea.length > 0) {
      await createUser({
        ...data,
        roles: optionsRol,
        areas: optionsArea,
        default_area_id: defaultArea,
      });
    } else {
      setDisabledButton(false);
      toast.error(t("pages.users.errorMessage"));
    }
    setDisabledButton(false);
  };

  const handleChooseLogo = (e: any) => {
    e.preventDefault();
    const file = e.target.files?.[0] || undefined;
    if (file) {
      loadImageWithValidations(file);
    }
  };

  const handleDeleteOptionRol = (value: string) => {
    if (!disabled) {
      setOptionRol((options) => options.filter((option) => option !== value));
    }
  };

  const handleDeleteOptionArea = (value: string) => {
    if (!disabled) {
      setOptionArea((options) => options.filter((option) => option !== value));
    }
  };

  const handleSelectOptionRol = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event?.target.value;
    const valueExist = optionsRol.find((option) => value === option);
    if (value && !disabled && !valueExist) {
      setOptionRol((options) => [...options, value]);
    }
  };
  const [selectedAreas, setSelectedAreas] = useState<Area[]>([]);

  useEffect(() => {
    if (optionsArea.length > 0) {
      const filterAreas: Area[] = areas.filter((area: Area) =>
        optionsArea.includes(String(area.id))
      );
      setSelectedAreas(filterAreas);
    } else {
      setSelectedAreas([]);
    }
  }, [optionsArea]);

  const handleSelectOptionArea = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event?.target.value;
    const valueExist = optionsArea.find((option) => value === option);
    if (value && !disabled && !valueExist) {
      setOptionArea((options) => [...options, value]);
    }
  };

  const handleSelectDefaultArea = (areaId: number) => {
    if (!disabled) {
      setDefaultArea(areaId ?? null);
    }
  };

  const handleChangeStatus = (event: React.FormEvent) => {
    event.preventDefault();
    disabled ? editing() : viewing();
  };

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault();
    if (isCreating) {
      navigate("/usuarios");
    } else {
      reset({ ...defaultValuesAdapter(user) });
      setImage(() => ({
        image_id: user.avatar_id,
        image_path: user.avatar_url,
      }));
      viewing();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-functional-el-l flex items-center justify-start px-10 py-7">
          <Link to="/usuarios">
            <span className="material-symbols-outlined mr-3 cursor-pointer border p-2 rounded">
              arrow_back
            </span>
          </Link>
          <div>
            <p className={`font-bold text-2xl text-functional-d-l`}>
              {infoStatus[status].title}
            </p>
            <p className={`font-medium text-functional-d-l`}>
              {infoStatus[status].message}
            </p>
          </div>
        </div>
        <div className="m-11 p-10 bg-functional-el-l rounded-3xl shadow-lg">
          <div className="flex justify-between">
            <div>
              <p className="font-bold text-xl text-text-functional-d-l">
                {t("pages.companies.generalData")}
              </p>
              <p className="font-medium text-functional-d-l">
                {t("pages.users.addData")}
              </p>
            </div>
            {!isCreating ? (
              <PrimaryButton onClick={handleChangeStatus}>
                {disabled ? t("generics.edit") : t("generics.seeInformation")}
              </PrimaryButton>
            ) : (
              <></>
            )}
          </div>
          <div className={cn("grid grid-cols-2", { "grid-rows-3": true })}>
            <div className="p-4">
              <InputText
                className="w-5/6"
                disabled={disabled}
                register={() =>
                  register("name", {
                    required: t("errorMessage.completeName"),
                    minLength: 3,
                  })
                }
                placeholder={t("generics.name")}
              />
              {errors.name ? (
                <p className="text-warning-d">{errors.name.message}</p>
              ) : null}
            </div>
            <div className="p-4">
              <InputText
                className="w-5/6"
                type="email"
                disabled={disabled}
                register={() =>
                  register("email", {
                    required: t("errorMessage.completeMail"),
                    minLength: 3,
                  })
                }
                placeholder={t("generics.mail")}
              />
              {errors.email ? (
                <p className="text-warning-d">{errors.email.message}</p>
              ) : null}
            </div>
            <div className="p-4">
              <TitleInput>{t("generics.phone")}</TitleInput>
              <div className="flex">
                <Select
                  className="w-2/12"
                  disabled={disabled}
                  options={countries}
                  optionmap={(value) => value.extension}
                  optionValueMap={(value) => value.extension}
                  register={() => register("code")}
                />
                <Input
                  className="w-8/12"
                  type="number"
                  disabled={disabled}
                  register={() =>
                    register("phone", {
                      required: t("errorMessage.completePhone"),
                      maxLength: 10,
                    })
                  }
                  placeholder={t("generics.phone")}
                />
              </div>
              {errors.phone ? (
                <p className="text-warning-d">{errors.phone.message}</p>
              ) : null}
            </div>
            <div className="row-span-2 p-4 w-5/6">
              <TitleInput>{t("pages.users.userPicture")}</TitleInput>
              <div className="flex flex-col items-center justify-center">
                <div className={cn("w-full my-2 h-[150px] row-span-2")}>
                  {/* <img src={isImageLoaded ? URL.createObjectURL(watchImage[0]) : "/imgLayout.png"} alt="" className="row-span-2 w-full h-full" /> */}
                  <img
                    src={image?.image_path || "/imgLayout.png"}
                    alt=""
                    className="row-span-2 w-full h-full object-contain"
                  />
                </div>
                {/* <Select className="self-end" options={['png', 'jpg', 'jpeg']} optionmap={(value) => value} register={() => register('typeImage')} /> */}
                <input
                  disabled={disabled}
                  type="file"
                  id="actual-btn"
                  accept="image/jpg, image/jpeg, image/png"
                  onChange={handleChooseLogo}
                  hidden
                />
                <label
                  htmlFor="actual-btn"
                  className={cn(
                    "self-end w-full bg-[#9A9A9A] text-lg flex items-center justify-center text-functional-el-l rounded-lg max-w-52 py-3 px-3 ml-1 h-14",
                    { "cursor-no-drop": disabled, "cursor-pointer": !disabled }
                  )}
                >
                  {/* <PlusIcon className="h-5 w-5 bg-functional-el-l text-functional-l-d mr-3.5 rounded-sm" /> */}
                  <span className="material-symbols-outlined bg-functional-el-l text-functional-l-d mr-3.5">
                    add
                  </span>
                  {t("generics.upload")} (512x512px en jpg o png)
                </label>
              </div>
            </div>
            {status === "creating" ? (
              <div className="p-4">
                <InputText
                  className="w-5/6"
                  type="password"
                  disabled={disabled}
                  register={() =>
                    register("password", {
                      required: t("errorMessage.completePassword"),
                    })
                  }
                  placeholder={t("generics.password")}
                />
                {errors.password ? (
                  <p className="text-warning-d">{errors.password.message}</p>
                ) : null}
              </div>
            ) : (
              <></>
            )}
            {/* <div className='p-4'>
              <p className='mb-1 text-lg text-black font-semibold'>{t('pages.modules.companyAreas')}:</p>
              <div className='flex flex-col'>
                <Select
                  value={'default'}
                  disabled={disabled}
                  className='w-5/6'
                  options={areas}
                  optionValueMap={(value) => value.id}
                  optionmap={(value) => value.name}
                  optionKeyMap={(value) => value.id}
                  onChange={handleSelectOptionArea}
                />
                <div className='flex flex-wrap mt-2 w-5/6'>
                  {optionsArea.length > 0 ? (
                    optionsArea.map((option) => (
                      <div
                        onClick={() => handleDeleteOptionArea(option)}
                        key={Math.random()}
                        className='h-10 mt-2 mr-2 bg-functional-l-l rounded-lg p-1 flex justify-center items-center'>
                        <p>{areaMapId[option]?.name ?? option}</p>
                        <div className='border border-functional-l-l h-full'></div>
                        <i className='mx-1 fa-solid fa-xmark hover:text-error-m' />
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div> */}
            <div className={`p-4 ${disabled && "!cursor-not-allowed"}`}>
              <p className="mb-1 text-lg text-black font-semibold">
                {t("pages.modules.companyAreas")}:
              </p>
              <div className="flex flex-col">
                <Select
                  value={"default"}
                  disabled={disabled}
                  className="w-5/6"
                  options={areas}
                  optionValueMap={(value) => value.id}
                  optionmap={(value) => value.name}
                  optionKeyMap={(value) => value.id}
                  onChange={handleSelectOptionArea}
                />
                <div className="flex flex-wrap mt-2 w-5/6">
                  {selectedAreas.length > 0 && (
                    <div className="w-full h-fit flex flex-col gap-2 ">
                      {selectedAreas.map((area: Area, index: number) => (
                        <div
                          key={index}
                          onClick={() => {
                            handleSelectDefaultArea(area.id);
                          }}
                          className={`w-full h-[40px] bg-gray-200 rounded-lg flex items-center justify-between p-4 cursor-pointer ${
                            disabled && "!cursor-not-allowed"
                          }`}
                        >
                          <div className="h-[20px] w-[20px] bg-white rounded-full border-[1px] border-black flex justify-center items-center  ">
                            {area.id === defaultArea && (
                              <div className="bg-blue-500 h-[80%] w-[80%] rounded-full"></div>
                            )}
                          </div>
                          <div> {area.name}</div>
                          <div
                            onClick={(
                              event: React.MouseEvent<
                                HTMLDivElement,
                                MouseEvent
                              >
                            ) => {
                              event.stopPropagation();
                              handleDeleteOptionArea(String(area.id));
                              if (area.id === defaultArea) {
                                setDefaultArea(null);
                              }
                            }}
                            className="text-[25px] hover:text-red-500"
                          >
                            <IoMdClose />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/* <Select
                  value={defaultArea ?? 'default'}
                  disabled={disabled}
                  className='w-5/6'
                  options={areas.filter((area) => optionsArea.includes(String(area.id)))}
                  optionValueMap={(value) => value.id}
                  optionmap={(value) => value.name}
                  optionKeyMap={(value) => value.id}
                  onChange={handleSelectDefaultArea}
                /> */}
              </div>
            </div>
            <div className="p-4">
              <p className="mb-1 text-lg text-black font-semibold">
                {t("pages.users.companyRol")}:
              </p>
              <div className="flex flex-col">
                <Select
                  value={"default"}
                  disabled={disabled}
                  className="w-5/6"
                  options={roles}
                  optionValueMap={(value) => value.id}
                  optionmap={(value) => value.name}
                  optionKeyMap={(value) => value.id}
                  onChange={handleSelectOptionRol}
                />
                <div className="flex flex-wrap mt-2 w-5/6">
                  {optionsRol.length > 0 ? (
                    optionsRol.map((option) => (
                      <div
                        onClick={() => handleDeleteOptionRol(option)}
                        key={Math.random()}
                        className="h-10 mt-2 mr-2 bg-functional-l-l rounded-lg p-1 flex justify-center items-center"
                      >
                        <p>{rolesMapId[option]?.name ?? option}</p>
                        <div className="border border-functional-l-l h-full"></div>
                        <i className="mx-1 fa-solid fa-xmark hover:text-error-m" />
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isCreating || isEditing ? (
            <div className="flex justify-end">
              <PrimaryButton
                disabled={disabled || disabledButton}
                type="submit"
              >
                {t("pages.users.saveUser")}
              </PrimaryButton>
              <SecondaryButton
                className="px-8"
                disabled={disabled}
                onClick={handleCancel}
              >
                {isCreating ? t("generics.goBack") : t("generics.cancel")}
              </SecondaryButton>
            </div>
          ) : null}
        </div>
        {(isEditing || disabled) && user?.id ? <UserLog userId={user.id}/> : null}
      </form>
    </>
  );
};
