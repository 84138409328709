import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Spinner } from '@/components/Spinners/Spinner';
import { useCrudUser } from '../hooks/useCrudUser';
import { UsuarioForm } from '../components/UsuarioForm';
import { useEffect, useState } from 'react';

export const CRUDUsuarios = () => {
  const { state } = useLocation();

  const [searchParams] = useSearchParams();
  const userIdParam = searchParams.get('userId');
  const [userId, setUserId] = useState<number | null>(null);
  const isRedirectParam = searchParams.get('fromReport');
  const navigate = useNavigate();

  const {
    // propiedades
    image,
    user,
    status,
    isLoading,
    companies,
    roles,
    areas,
    countries,
    // Metodos,
    editing,
    viewing,
    setImage,
    updateUser,
    createUser,
    loadImageWithValidations
  } = useCrudUser(state?.userSelected ?? userId);

  useEffect(() => {
    loadData();
  }, [userId]);

  /**
   * Loads and validates the user ID from URL parameters
   * @remarks
   * - Checks if user ID exists in URL params and decrypts it from base64
   * - Validates the decrypted ID is a valid number
   * - Sets the validated user ID in state if valid
   * - Navigates to companies page if validation fails
   * - Early returns if user is already selected via route state
   * @throws {Error} If userId param is missing
   */
  const loadData = async () => {
    // Skip if user already selected via route state
    if (state?.userSelected) return;
    if (!isRedirectParam) return;
    try {
      if (!userIdParam && isRedirectParam) throw new Error("userId is required");

      // Decrypt base64 encoded user ID
      const decryptedId = atob(userIdParam ?? "");

      // Validate decrypted ID is a valid number
      const isValidUserId =
        decryptedId &&
        decryptedId !== "undefined" &&
        !isNaN(Number(decryptedId));

      const userIdDecrypted = Number(decryptedId);

      if (!isValidUserId) {
        navigate('/empresas');
        return;
      }

      setUserId(userIdDecrypted);
    }
    catch(err) {
      console.error(err);
      navigate('/empresas');
    }
  }

  return (
    <article className='bg-functional-el-m'>
      {user && roles && areas && countries && companies ? (
        <UsuarioForm
          {...{
            setImage,
            companies,
            roles,
            countries,
            // optionsRol, optionsArea, setOptionArea, setOptionRol,
            image,
            user,
            status,
            isLoading,
            editing,
            viewing,
            updateUser,
            createUser,
            areas,
            loadImageWithValidations
          }}
        />
      ) : (
        <Spinner />
      )}
    </article>
  );
};
