import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdAutorenew,
  MdRemoveRedEye
} from "react-icons/md";
import { Loader } from "../dashboard/components/Loader";
import {
  REPORT_ORDER,
  INTERACTIONS_REPORT_FILTERS,
  InteractionReport,
  INTERACTION_TYPES,
} from "./interfaces/reports";
import { useInteractionsReport } from "./hooks/useInteractionsReport";
import { useLoadCampanies } from "@/hooks";
import { Select } from "@/components";
import { useNavigate } from "react-router-dom";

export const InteractionsReportsPage = (): JSX.Element => {
  const {
    isLoading,
    currentPage,
    totalPages,
    interactionsReports,
    nextPage,
    previousPage,
    lastPage,
    firstPage,
    loadInteractionsReports,
    isDownloadingReport,
    downloadReport,
  } = useInteractionsReport();
  const [search, setSearch] = useState<string>("");
  const [sortType, setSortType] = useState<
    INTERACTIONS_REPORT_FILTERS | undefined
  >();
  const [sortOrder, setSortOrder] = useState<REPORT_ORDER | undefined>();
  const [companySlug, setCompanySlug] = useState<string | undefined>();
  const { loadCompanies, companies } = useLoadCampanies();
  const navigate = useNavigate();

  useEffect(() => {
    setSearch("");
    setSortType(undefined);
    setSortOrder(undefined);
    if (companySlug) {
      loadInteractionsReports(1, "", undefined, undefined, companySlug);
    }
  }, [companySlug]);

  useEffect(() => {
    loadCompanies();
  }, []);

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    debouncedSearch(value);
  };

  const handleSort = (type: INTERACTIONS_REPORT_FILTERS) => {
    if (isLoading) {
      return;
    }
    let order;
    if (sortType === type) {
      if (sortOrder === REPORT_ORDER.DESC) {
        setSortType(undefined);
        setSortOrder(undefined);
        loadInteractionsReports(1, search, sortType, sortOrder, companySlug);
        return;
      }
      order =
        sortOrder === REPORT_ORDER.ASC ? REPORT_ORDER.DESC : REPORT_ORDER.ASC;
      setSortOrder(order);
    } else {
      setSortType(type);
      order = REPORT_ORDER.ASC;
      setSortOrder(order);
    }
    loadInteractionsReports(1, search, type, order, companySlug);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      loadInteractionsReports(1, query, sortType, sortOrder, companySlug);
    }, 300),
    [companySlug, sortType, sortOrder]
  );

  const goToInteraction = (interaction: InteractionReport) => {
    switch (interaction.type) {
      case INTERACTION_TYPES.SURVEY:
        navigate(`/interacciones/encuestas/area/${interaction.area_id}/encuesta/${interaction.slug}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="w-full h-auto">
        <div className="w-full rounded-lg h-auto p-4 pb-2 bg-white flex justify-between gap-x-[30px]">
          <div className="w-full justify-between items-center">
            <h2 className="text-[30px] font-semibold text-start text-black">
              {t("pages.reports.interactionsReports")}
            </h2>
            <p className="text-start">{t("pages.reports.availableReports")}</p>
          </div>
          <Select
            onChange={(e) => setCompanySlug(e.target.value)}
            defaultValue={"default"}
            className="w-auto"
            options={companies}
            optionmap={(value) => value.name}
            optionValueMap={(value) => value.slug}
            optionKeyMap={(value) => value.id}
            value={companySlug || "default"}
          />
        </div>
        {companySlug && (
          <div className="w-full h-auto p-4 mt-4 rounded-lg bg-[#f6f9fe] min-h-[calc(100vh-180px)] overflow-hidden">
            <div className="w-full flex justify-end items-center gap-4 flex-wrap mb-[30px]">
              {/* Input search */}
              <input
                type="text"
                placeholder={t("generics.search")}
                className="w-[200px] h-[40px] rounded-lg border border-gray-300 p-2 bg-[#eaeaea]"
                onChange={handleChangeSearch}
                value={search}
              />
              {/* Button Download Xsls*/}
              <button
                className="px-4 py-2 rounded-lg bg-[#000] text-white cursor-pointer w-[170px] flex items-center justify-center"
                onClick={() => downloadReport(companySlug)}
              >
                {isDownloadingReport ? (
                  <MdAutorenew className="animate-spin text-[20px]" />
                ) : (
                  <>{t("generics.download")} XLSX</>
                )}
              </button>
            </div>
            {/* Table */}
            <div className="w-full h-auto bg-white rounded-lg p-4 shadow-md overflow-x-auto">
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <div className="relative h-auto min-h-[300px] w-full rounded-lg overflow-hidden bg-white overflow-y-auto p-4 min-w-[1400px]">
                    <div className="sticky w-full flex justify-between top-0 bg-[#ededed] z-[2] rounded-xl mb-[10px]">
                      <div className={`${headerCell} min-w-[150px]`}>Id</div>
                      <div
                        className={`${headerCell} w-full max-w-[300px] min-w-[300px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(INTERACTIONS_REPORT_FILTERS.LESSON)
                        }
                      >
                        {sortType === INTERACTIONS_REPORT_FILTERS.LESSON && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.lesson")}
                      </div>
                      <div
                        className={`${headerCell} w-full max-w-[350px] min-w-[350px] hover:bg-[#dedede] transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(INTERACTIONS_REPORT_FILTERS.TITLE)
                        }
                      >
                        {sortType === INTERACTIONS_REPORT_FILTERS.TITLE && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.title")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[180px] min-w-[180px] hover:bg-[#dedede] transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(INTERACTIONS_REPORT_FILTERS.TYPE)
                        }
                      >
                        {sortType === INTERACTIONS_REPORT_FILTERS.TYPE && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.type")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[150px] min-w-[150px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(INTERACTIONS_REPORT_FILTERS.COMPLETED)
                        }
                      >
                        {sortType === INTERACTIONS_REPORT_FILTERS.COMPLETED && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.completed")}
                      </div>
                      <div
                        className={`${headerCell} max-w-[120px] min-w-[120px] hover:bg-[#dedede] cursor-pointer transition-all select-none ${
                          isLoading ? "cursor-not-allowed" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          handleSort(INTERACTIONS_REPORT_FILTERS.STATE)
                        }
                      >
                        {sortType === INTERACTIONS_REPORT_FILTERS.STATE && (
                          <span className="mr-1">
                            {sortOrder === REPORT_ORDER.ASC ? (
                              <MdArrowUpward />
                            ) : (
                              <MdArrowDownward />
                            )}
                          </span>
                        )}
                        {t("pages.reports.state")}
                      </div>
                      <div
                        className={`${headerCell} min-w-[80px] max-w-[80px]`}
                      ></div>
                    </div>
                    {interactionsReports.map((interactionReport, index) => (
                      <>
                        <div
                          className={`w-full flex justify-between border-b border-gray-200 ${
                            index % 2 === 0
                              ? "bg-white hover:bg-gray-50"
                              : "bg-slate-100 hover:bg-slate-200"
                          }`}
                          key={interactionReport.id}
                        >
                          <div className={`${bodycell} min-w-[150px]`}>
                            {interactionReport.id}
                          </div>
                          <div
                            className={`${bodycell} w-full max-w-[300px] min-w-[300px]`}
                          >
                            {interactionReport.lesson ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} w-full max-w-[350px] min-w-[350px]`}
                          >
                            {interactionReport.name ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[180px] min-w-[180px]`}
                          >
                            {interactionReport.type ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[150px] min-w-[150px]`}
                          >
                            {interactionReport.completed ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} max-w-[120px] min-w-[120px]`}
                          >
                            {interactionReport.status ?? "-"}
                          </div>
                          <div
                            className={`${bodycell} min-w-[80px] max-w-[80px]`}
                          >
                            {
                              interactionReport.type === INTERACTION_TYPES.SURVEY && (
                                <button
                                  onClick={() => goToInteraction(interactionReport)}
                                  className={`${buttonAction} text-white hover:bg-primary-l-d`}
                                  title="Editar"
                                  type="button"
                                >
                                  <MdRemoveRedEye />
                                </button>
                              )
                            }
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="flex justify-center mt-4">
                    {currentPage > 1 && (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            firstPage(search, sortType, sortOrder, companySlug);
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            first_page
                          </span>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            previousPage(search, sortType, sortOrder, companySlug);
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            keyboard_arrow_left
                          </span>
                        </button>
                      </>
                    )}
                    <p className="p-2">
                      {currentPage}-{totalPages}
                    </p>
                    {currentPage < totalPages && (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            nextPage(search, sortType, sortOrder, companySlug);
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            keyboard_arrow_right
                          </span>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            lastPage(search, sortType, sortOrder, companySlug);
                          }}
                        >
                          <span className="material-symbols-outlined cursor-pointer text-3xl">
                            last_page
                          </span>
                        </button>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const headerCell =
  "text-left min-h-[50px] px-4 font-semibold flex items-center text-sm";
const buttonAction = "bg-primary-l-m text-[20px] p-2 rounded-lg right-0 mr-2";
const bodycell =
  "relative text-left min-h-[50px] px-4 flex items-center word-break";
