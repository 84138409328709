import {t} from "i18next";
import cn from 'classnames';
import {Loader} from "./components/Loader";
import {MdVisibility} from "react-icons/md";
import {useSocial} from "./hooks/useSocial";
import {useEffect, useState} from "react";
import {FILTER_STATUS, FILTER_STATUS_OPTIONS} from "./constants/filters.social";
import {PreviewComment} from "./components/PreviewComment";
import {Comment} from "./interface/social.interface";

const index = 1;

export function SocialPage(): JSX.Element {

  const {
    isLoading,
    searchComments,
    comments,
    currentPage,
    totalPages,
    loadLessons,
    loadModules,
    lessons,
    modules,
    nextPage,
    firstPage,
    lastPage,
    previousPage,
    approveComment,
    rejectComment
  } = useSocial();
  // Filters
  const [status, setStatus] = useState(FILTER_STATUS.all);
  const [module, setModule] = useState<string | undefined>(undefined);
  const [lesson, setLesson] = useState<string | undefined>(undefined);
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [commentPreview, setCommentPreview] = useState<Comment | undefined>(undefined);

  // Load comments and modules
  useEffect(() => {
    searchComments(status, 1, module, lesson, phone)
    loadModules()
  }, [])

  /**
   * The `handleSearch` function calls the `searchComments` function with specific parameters.
   */
  const handleSearch = () => {
    searchComments(status, 1, module, lesson, phone)
  }

  /**
   * The handleChangeModule function updates the module state and loads lessons based on the selected
   * module value.
   * @param e - The parameter `e` in the `handleChangeModule` function is a React ChangeEvent that is
   * triggered when the value of a HTMLSelectElement (a dropdown select element) changes.
   */
  const handleChangeModule = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    if (value === 'default') {
      setModule(undefined)
      return
    }
    setModule(value)
    loadLessons(value).catch(console.error)
  }

  /**
   * The `closePreview` function sets the `commentPreview` state to `undefined`.
   */
  const closePreview = () => {
    setCommentPreview(undefined)
  }

  /**
   * The `openPreview` function sets the comment preview to the provided comment.
   * @param {Comment} comment - A comment object that contains information about a user's comment, such
   * as the text content, author, timestamp, etc.
   */
  const openPreview = (comment: Comment) => {
    setCommentPreview(comment)
  }

  /**
   * The function `processStatus` takes a status string as input and returns a corresponding translation
   * based on the status value.
   * @param {string} status - It looks like the `processStatus` function is designed to take a `status`
   * parameter of type string and return a corresponding message based on the value of the `status`. The
   * function uses a switch statement to check the value of `status` against predefined constants like
   * `FILTER_STATUS.approved`,
   * @returns The `processStatus` function takes a `status` parameter and returns a corresponding
   * translation based on the value of the `status`. If the `status` is `approved`, it returns the
   * translation for "pages.social.approved". If the `status` is `pending`, it returns the translation
   * for "pages.social.pending". If the `status` is `rejected`, it returns the translation for
   */
  const processStatus = (status: string) => {
    switch (status) {
      case FILTER_STATUS.approved:
        return t('pages.social.approved')
      case FILTER_STATUS.pending:
        return t('pages.social.pending')
      case FILTER_STATUS.rejected:
        return t('pages.social.rejected')
      default:
        return t('pages.social.all')
    }
  }

  /**
   * The function `getColorClassStatus` returns a CSS class based on the input status value.
   * @param {string} status - It seems like you were about to provide the possible values for the
   * `status` parameter in the `getColorClassStatus` function. Could you please provide those values so
   * that I can assist you further?
   * @returns The function `getColorClassStatus` returns a CSS class based on the input `status`
   * parameter. If the `status` is `approved`, it returns `'text-green-500 font-bold'`, if the `status`
   * is `pending`, it returns `'text-yellow-500 font-bold'`, if the `status` is `rejected`, it returns
   * `'text-red-500 font-bold'`,
   */
  const getColorClassStatus = (status: string) => {
    switch (status) {
      case FILTER_STATUS.approved:
        return 'text-green-500 font-bold';
      case FILTER_STATUS.pending:
        return 'text-yellow-500 font-bold';
      case FILTER_STATUS.rejected:
        return 'text-red-500 font-bold';
      default:
        return 'text-black';
    }
  }

  return (
    <>
      <div className="w-full p-4 text-center min-w-[900px]">
        <span className={cn('material-symbols-outlined text-[70px]')}>groups</span>
        <h2 className='text-[30px] font-semibold'>{t('generics.social')}</h2>
        <p>{t('others.socialMessage')}</p>
        {/* Filtros */}
        <div className='flex justify-center mt-4'>
          <div className='flex items-center'>
            <div className='mr-2 flex flex-col'>
              <label htmlFor='filter' className='mr-2'>{t('pages.social.status')}:</label>
              <select id='filter' className='p-2 border border-gray-300 rounded-lg'
                      value={status}
                      defaultValue={status}
                      onChange={(v) =>
                        setStatus(v.target.value)}>
                {
                  FILTER_STATUS_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>{t(option.label)}</option>
                  ))
                }
              </select>
            </div>
            <div className='mr-2 flex flex-col w-[200px]'>
              <label htmlFor='module' className='mr-2'>{t('pages.social.module')}:</label>
              <select id='module' className='p-2 border border-gray-300 rounded-lg'
                      value={module}
                      onChange={handleChangeModule}
                      defaultValue={'default'}
              >
                <option disabled={true} value='default'>
                  {t('generics.chooseOne')}
                </option>
                {
                  modules.map((option) => (
                    <option key={option.id} value={option.slug}>{option.name}</option>
                  ))
                }
              </select>
            </div>
            {
              lessons.length > 0 && (
                <div className='mr-2 flex flex-col w-[200px]'>
                  <label htmlFor='lesson' className='mr-2'>{t('pages.social.lesson')}:</label>
                  <select id='lesson' className='p-2 border border-gray-300 rounded-lg'
                          value={lesson}
                          onChange={(v) => {
                            const value = v.target.value === 'default' ? undefined : v.target.value
                            setLesson(value)
                          }
                          }>
                    <option disabled={true} value='default'>
                      {t('generics.chooseOne')}
                    </option>
                    {
                      lessons.map((option) => (
                        <option key={option.slug} value={option.slug}>{option.name}</option>
                      ))
                    }
                  </select>
                </div>
              )
            }
            <div className='mr-2 flex flex-col'>
              <label htmlFor='phone' className='mr-2'>{t('generics.phone')}:</label>
              <input id='phone' className='p-2 border border-gray-300 rounded-lg w-[200px]'
                     value={phone}
                     onChange={(v) => setPhone(v.target.value)}
                     placeholder="+571212129"
              />
            </div>

          </div>
          <button
            className='bg-primary-l-m text-white p-2 rounded-lg ml-2 px-3 disabled:opacity-50 disabled:cursor-not-allowed h-[40px] self-end'
            type="button"
            disabled={isLoading}
            onClick={handleSearch}>{t('generics.search')}</button>
        </div>
        <div
          className='relative h-auto min-h-[300px] w-full rounded-lg overflow-hidden bg-gray-50 overflow-y-auto shadow-md mt-[40px]'>
          <div className='sticky w-full flex justify-between top-0 bg-gray-50 z-[2]'>
            <div className={`${headerCell} min-w-[70px]`}>Id</div>
            <div className={`${headerCell} w-full max-w-[200px]`}>{t('pages.social.user')}</div>
            <div className={`${headerCell} w-full`}>{t('pages.social.comment')}</div>
            <div className={`${headerCell} w-full`}>{t('pages.social.module')}</div>
            <div className={`${headerCell} w-full`}>{t('pages.social.lesson')}</div>
            <div className={`${headerCell} min-w-[180px] max-w-[200px]`}>{t('pages.social.status')}</div>
            <div className={`${headerCell} min-w-[150px] max-w-[150px]`}>{t('pages.social.actions')}</div>
          </div>
          {
            comments.map((comment) => (
              <>
                <div className={`w-full flex justify-between ${index % 2 ? 'bg-slate-100' : 'bg-slate-200'}`}
                     key={comment.id}>
                  <div className={`${bodycell} min-w-[70px]`}>{comment.id}</div>
                  <div className={`${bodycell} w-full max-w-[200px]`}>{comment.user}</div>
                  <div className={`${bodycell} w-full`}>{comment.comment}</div>
                  <div className={`${bodycell} w-full`}>{comment.module_name}</div>
                  <div className={`${bodycell} w-full`}>{comment.lesson_name}</div>
                  <div
                    className={`${bodycell} min-w-[180px] ${getColorClassStatus(comment.status)}`}>{processStatus(comment.status)}</div>
                  <div className={`${bodycell} min-w-[150px] max-w-[150px] justify-between`}>
                    <button
                      onClick={() => openPreview(comment)}
                      className={`${buttonAction} text-white hover:bg-primary-l-d`}
                      title="Editar"
                      type="button">
                      <MdVisibility/>
                    </button>
                  </div>
                </div>
              </>
            ))
          }
        </div>
        {/* Paginator */}
        <div className='flex justify-center mt-4'>
          {
            currentPage > 1 && (
              <>
                <button type="button"
                        onClick={() => {
                          firstPage(status, module, lesson, phone)
                        }}>
                  <span className="material-symbols-outlined cursor-pointer text-3xl">first_page</span>
                </button>
                <button type="button"
                        onClick={() => {
                          previousPage(status, module, lesson, phone)
                        }}
                >
                  <span className="material-symbols-outlined cursor-pointer text-3xl">keyboard_arrow_left</span>
                </button>
              </>
            )
          }
          <p className='p-2'>{currentPage}-{totalPages}</p>
          {
            currentPage < totalPages && (
              <>
                <button type="button"
                        onClick={() => {
                          nextPage(status, module, lesson, phone)
                        }}
                >
                  <span className="material-symbols-outlined cursor-pointer text-3xl">keyboard_arrow_right</span>
                </button>
                <button type="button"
                        onClick={() => {
                          lastPage(status, module, lesson, phone)
                        }}
                >
                  <span className="material-symbols-outlined cursor-pointer text-3xl">last_page</span>
                </button>
              </>
            )
          }
        </div>
      </div>

      {
        isLoading && <Loader/>
      }
      {
        commentPreview &&
          <PreviewComment comment={commentPreview} closePreview={closePreview} approveComment={approveComment}
                          rejectComment={rejectComment}/>
      }
    </>
  );
}

const headerCell = 'text-left min-h-[50px] px-4 font-semibold flex items-center truncate';
const buttonAction = 'bg-primary-l-m text-[20px] p-2 rounded-lg right-0 mr-2';
const bodycell = 'relative text-left min-h-[50px] px-4 flex items-center truncate';