import { t } from "i18next"
import { INTERACTIONS, INTERACTIONS_OPTIONS } from "@/constants";
import { useEffect, useState } from "react";
import { Surveys, Tasks } from "./components";
import { useAreas } from "./hooks/useAreas";


export const InteractionsPage = (): JSX.Element => {

  const [currentTab, setCurrentTab] = useState(INTERACTIONS.SURVEYS)
  const [areaId, setAreaId] = useState<number | undefined>(undefined)
  const { loadAreas, areas } = useAreas();

  useEffect(() => {
    loadAreas()
  }, [])

  /**
   * The handleChangeArea function updates the areaId state based on the value selected in a dropdown
   * menu.
   * @param e - The parameter `e` in the `handleChangeArea` function is a React ChangeEvent that
   * represents the event triggered when the value of a select element (HTMLSelectElement) changes.
   */
  const handleChangeArea = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'default') {
      return setAreaId(undefined)
    }
    setAreaId(parseInt(e.target.value))
  }

  return (
    <div className="w-full text-center min-w-[900px] h-auto">
      <div className="w-full rounded-lg h-auto p-4 bg-white flex justify-between gap-x-[30px]">
        <div className="w-full justify-between items-center">
          <h2 className='text-[30px] font-semibold text-start text-black'>{t('generics.interactions')}</h2>
          <p className="text-start">{t('others.interactionsMessage')}</p>
        </div>
        {/* Area */}
        <div className="w-[300px] flex justify-between items-center mt-4">
          <select className="w-full h-[40px] rounded-lg border border-gray-300 p-2"
            onChange={handleChangeArea}
            value={areaId}
            defaultValue={"default"}
          >
            <option value={"default"} disabled>{t("generics.chooseOne")}</option>
            {
              areas.map((area) => (
                <option key={area.id} value={area.id}>{area.name}</option>
              ))
            }
          </select>
        </div>
      </div>
      {
        areaId && (
          <div className="w-full h-auto p-4 mt-4 rounded-lg bg-[#f5f5f5] min-h-[calc(100vh-180px)]">
            {/* Buttons */}
            <div className="flex justify-center items-center gap-4 flex-wrap mb-[30px]">
              {
                [...INTERACTIONS_OPTIONS].map((option) => (
                  <button
                    key={option}
                    onClick={() => setCurrentTab(option)}
                    className={`px-4 py-2 rounded-lg ${currentTab === option ? 'bg-[#000] text-white cursor-not-allowed' : 'bg-white text-gray-400 hover:bg-gray-200'} disabled:cursor-not-allowed`}
                    disabled={option !== INTERACTIONS.SURVEYS}
                  >
                    {option}
                  </button>
                ))
              }
            </div>
            {
              currentTab === INTERACTIONS.SURVEYS && (
                <>
                  <Surveys areaId={areaId}/>
                </>
              )
            }
            {
              currentTab === INTERACTIONS.TASKS && (
                <>
                  <Tasks />
                </>
              )
            }
            {
              currentTab === INTERACTIONS.OTHERS && (
                <>
                  {/* <h1>Others</h1> */}
                </>
              )
            }
          </div>
        )
      }
    </div>
  )
}
