import useAxiosPrivate from "@/hooks/useApiV3Private"
import { useRef, useState } from "react"
import toast from "react-hot-toast"
import { Log } from "../interfaces/logs"

export const useLogs = (userId: number) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloadingLogs, setIsDownloadingLogs] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [logs, setLogs] = useState<Log[]>([])
  const intervalIdRef = useRef<number | undefined>()
  const apiV3 = useAxiosPrivate()

  const loadLogs = async (page: number, sortType?: string, sortOrder?: string) => {
    try {
      setIsLoading(true)
      const queryParameters = new URLSearchParams()
      queryParameters.append('page', page.toString())
      queryParameters.append('per_page', '10')
      queryParameters.append('user_id', userId.toString())
      if (sortType) {
        queryParameters.append('order_by', sortType)
      }
      if (sortOrder !== undefined) {
        queryParameters.append('order', sortOrder)
      }
      const res = await apiV3.get(`/logs?${queryParameters.toString()}`)
      const data = res.data
      setLogs(data.items.data)
      setCurrentPage(data.pagination.current_page)
      setTotalPages(data.pagination.last_page)
    }
    catch (err) {
      console.error('Error cargando los logs', err)
      toast.error('Error cargando los logs')
    }
    finally {
      setIsLoading(false)
    }
  }

  const nextPage = async (sortType?: string, sortOrder?: string) => {
    if (currentPage < totalPages) {
      await loadLogs(currentPage + 1, sortType, sortOrder)
    }
  }

  const firstPage = (sortType?: string, sortOrder?: string) => {
    loadLogs(1, sortType, sortOrder)
  }

  const previousPage = async (sortType?: string, sortOrder?: string) => {
    if (currentPage > 1) {
      await loadLogs(currentPage - 1, sortType, sortOrder)
    }
  }

  const lastPage = async (sortType?: string, sortOrder?: string) => {
    if (currentPage < totalPages) {
      await loadLogs(totalPages, sortType, sortOrder)
    }
  }

  const downloadLogs = async (userId: number) => {
    setIsDownloadingLogs(true)
    try {
      const res = await apiV3.get(`/logs?export=true&format=xlsx&user_id=${userId}`, {
        responseType: 'blob'
      })
      // Download the file
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      const currentDate = new Date()
        .toLocaleDateString('es-ES',
          {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
        .replace(/\//g, '_')
      const fileName = `logs_usuario_${userId}_${currentDate}.xlsx`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    catch(err) {
      console.error('Error descargando los logs', err)
      toast.error('Error descargando los logs')
    }
    finally {
      setIsDownloadingLogs(false)
    }
  }

  return {
    isLoading,
    currentPage,
    totalPages,
    nextPage,
    firstPage,
    previousPage,
    lastPage,
    logs,
    loadLogs,
    intervalIdRef,
    isDownloadingLogs,
    downloadLogs
  }

}