import { t } from "i18next"
import { useState } from "react"
import toast from "react-hot-toast"
import { REPORT_ORDER, UserReport, USERS_REPORT_FILTERS, UsersReportResponse } from "../interfaces/reports"
import { apiV3 } from "@/apis/apiV3"


export const useUsersReport = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [usersReports, setUsersReports] = useState<UserReport[]>([])
  const [isDownloadingReport, setIsDownloadingReport] = useState(false)

  const loadUsersReports = async (page = 1, search?: string, type?: USERS_REPORT_FILTERS, order?: REPORT_ORDER, companySlug?: string) => {
    if (!companySlug) {
      return
    }
    setIsLoading(true)
    const queryParameters = new URLSearchParams()
    queryParameters.append('page', page.toString())
    queryParameters.append('per_page', '20')
    if (order) {
      queryParameters.append('order', order)
    }
    if (type) {
      queryParameters.append('order_by', type)
    }
    if (search) {
      queryParameters.append('search', search)
    }
    try {
      const res = await apiV3.get(`/companies/${companySlug}/reports/users?${queryParameters.toString()}`)
      if (!res.data) throw new Error('No data')
      const data: UsersReportResponse = res.data
      setCurrentPage(data.pagination.current_page)
      setTotalPages(data.pagination.last_page)
      setUsersReports(data.items)
    }
    catch (err) {
      toast.error(t('pages.reports.errorLoading'))
      console.error(err)
    }
    finally {
      setIsLoading(false)
    }
  }


  /**
   * The nextPage function increments the current page number and loads surveys for a specific area
   * asynchronously.
   * @param {number} areaId - The `areaId` parameter is a number that is used as an identifier for a
   * specific area in the application. It is passed to the `nextPage` function to load surveys related
   * to that particular area.
   */
  const nextPage = async (search?: string, type?: USERS_REPORT_FILTERS, order?: REPORT_ORDER, companySlug?: string) => {
    if (currentPage < totalPages) {
      await loadUsersReports(currentPage + 1, search, type, order, companySlug)
    }
  }

  /**
   * The function `firstPage` sets the current page to 1 and loads surveys based on the provided area
   * ID.
   * @param {number} areaId - The `areaId` parameter is a number that represents the unique identifier
   * of a specific area. It is used as a parameter in the `firstPage` function to load surveys related
   * to that particular area.
   */
  const firstPage = (search?: string, type?: USERS_REPORT_FILTERS, order?: REPORT_ORDER, companySlug?: string) => {
    loadUsersReports(1, search, type, order, companySlug)
  }

  /**
   * The function `previousPage` decreases the current page number by 1 if it's greater than 1 and then
   * loads surveys for a specific area.
   * @param {number} areaId - The `areaId` parameter in the `previousPage` function is a number that
   * represents the identifier of a specific area. This parameter is used to load surveys related to
   * that particular area when navigating to the previous page.
   */
  const previousPage = (search?: string, type?: USERS_REPORT_FILTERS, order?: REPORT_ORDER, companySlug?: string) => {
    if (currentPage > 1) {
      loadUsersReports(currentPage - 1, search, type, order, companySlug)
    }
  }

  /**
   * The function `lastPage` sets the current page to the total number of pages and loads surveys for a
   * specific area.
   * @param {number} areaId - The `areaId` parameter is a number that represents the unique identifier
   * of a specific area or location. It is used as a reference to load surveys related to that
   * particular area.
   */
  const lastPage = (search?: string, type?: USERS_REPORT_FILTERS, order?: REPORT_ORDER, companySlug?: string) => {
      loadUsersReports(totalPages, search, type, order, companySlug)
  }

  const downloadReport = async (companySlug?: string) => {
    if (!companySlug) {
      return
    }
    setIsDownloadingReport(true)
    try {
      const res = await apiV3.get(`/companies/${companySlug}/reports/users?format=xlsx`, {
        responseType: 'blob',
      })

      const url = window.URL.createObjectURL(res.data)
      const link = document.createElement('a')
      link.href = url
      const currentDate = new Date().toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '_')
      link.setAttribute('download', `simon_reporte_usuarios_${currentDate}.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    catch (err) {
      console.error('Error descargando el reporte', err)
      toast.error('Error descargando el reporte')
    }
    finally {
      setIsDownloadingReport(false)
    }
  }

  return {
    isLoading,
    currentPage,
    totalPages,
    usersReports,
    firstPage,
    nextPage,
    previousPage,
    lastPage,
    loadUsersReports,
    downloadReport,
    isDownloadingReport
  }
}
