import { t } from "i18next";
import { FILTER_STATUS } from "../constants/filters.social";
import { Comment } from "../interface/social.interface";
import { useEffect, useState } from "react";

interface Props {
  comment: Comment;
  closePreview: () => void;
  approveComment: (idComment: number) => Promise<void>;
  rejectComment: (idComment: number, reason?: string) => Promise<void>;
}

export const PreviewComment = ({ comment, closePreview, approveComment, rejectComment }: Props) => {
  const [isActing, setIsActing] = useState(false);
  const [status, setStatus] = useState<string | undefined>();
  const [rejectionReason, setRejectionReason] = useState('');

  useEffect(() => {
    if (comment && comment.status !== FILTER_STATUS.pending) {
      setStatus(comment.status);
    }
    if (comment && comment.rejection_reason) {
      setRejectionReason(comment.rejection_reason);
    }
  }, [comment])

  /**
   * The `handleContainerClick` function closes a preview.
   */
  const handleContainerClick = () => {
    closePreview();
  };

  /**
   * The handleBoxClick function in TypeScript React prevents event propagation.
   * @param event - The `event` parameter in the `handleBoxClick` function is a React MouseEvent
   * object. This object contains information about the event that occurred, such as the type of event,
   * the target element, and any additional event-specific data.
   */
  const handleBoxClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  /**
   * The `handleSave` function checks the status and calls either `handleApprove` or `handleReject`
   * based on the status value.
   */
  const handleSave = () => {
    if (status === FILTER_STATUS.approved) {
      handleApprove();
    } else if (status === FILTER_STATUS.rejected) {
      handleReject();
    }
  }

  /**
   * The function `handleApprove` asynchronously approves a comment, handles errors, and updates the
   * state accordingly.
   */
  const handleApprove = async () => {
    try {
      setIsActing(true);
      await approveComment(comment.id);
      closePreview();
    }
    catch (err) {
      console.error('Error aprobando el comentario', err);
    }
    finally {
      setIsActing(false);
    }
  }

  /**
   * The function `handleReject` sets a loading state, rejects a comment, closes a preview, and handles
   * any errors that occur.
   */
  const handleReject = () => {
    try {
      setIsActing(true);
      rejectComment(comment.id, rejectionReason);
      closePreview();
    }
    catch (err) {
      console.error('Error rechazando el comentario', err);
    }
    finally {
      setIsActing(false);
    }
  }

  /**
   * The function `processStatus` takes a status string as input and returns a corresponding
   * translation based on the status value.
   * @param {string} status - It seems like you have defined a function `processStatus` that takes a
   * `status` parameter of type string and returns a corresponding message based on the value of the
   * `status`. The function uses a `switch` statement to check the value of `status` against predefined
   * constants like `FILTER_STATUS.app
   * @returns The `processStatus` function takes a `status` parameter and returns a corresponding
   * message based on the value of the `status`. If the `status` is `approved`, it returns the message
   * `'pages.social.approved'`. If the `status` is `pending`, it returns the message
   * `'pages.social.pending'`. If the `status` is `rejected`, it returns the message `'
   */
  const processStatus = (status: string) => {
    switch (status) {
      case FILTER_STATUS.approved:
        return t('pages.social.approved')
      case FILTER_STATUS.pending:
        return t('pages.social.pending')
      case FILTER_STATUS.rejected:
        return t('pages.social.rejected')
      default:
        return t('pages.social.all')
    }
  }

  return (
    <div
      className="w-[100vw] h-auto min-h-[100vh] flex justify-center items-center fixed top-0 left-0 z-[999] bg-[#0000005b]"
      onClick={handleContainerClick}
    >
      <div
        className="max-w-[700px] w-[80%] h-auto pt-[30px] pb-[30px] bg-white rounded-3xl p-4"
        onClick={handleBoxClick}
      >
        {/* Contenido del cuadro blanco */}
        <div className="flex items-center flex-col">
          <div className="flex items-center justify-between w-full mb-4">
            <h2 className="text-2xl font-bold">Comentario</h2>
            <button className="text-2xl" onClick={closePreview}>
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>

          <div className="flex flex-col w-full">

            <div className="p-4 bg-white shadow-md rounded-lg">
              <div className="flex items-center mb-2">
                <p className="font-semibold text-gray-700">Usuario:</p>
                <p className="ml-2 text-gray-600">{comment.user}</p>
              </div>

              <div className="flex items-center mb-2">
                <p className="font-semibold text-gray-700">Módulo:</p>
                <p className="ml-2 text-gray-600">{comment.module_name}</p>
              </div>

              <div className="flex items-center mb-2">
                <p className="font-semibold text-gray-700">Lección:</p>
                <p className="ml-2 text-gray-600">{comment.lesson_name}</p>
              </div>

              <div className="flex items-center mb-2">
                <p className="font-semibold text-gray-700">Estado:</p>
                <p className="ml-2 text-gray-600">{processStatus(comment.status)}</p>
              </div>

              <div className="mt-4 p-4 bg-gray-100 rounded-lg">
                <p className="font-semibold text-gray-700">Comentario:</p>
                <p className="mt-2 text-gray-600">{comment.comment}</p>
              </div>
            </div>

            {/* Boton aprobar desaprobar */}
            <div className="flex justify-center mt-4 flex-col max-w-[300px] m-auto items-center">
              <span className="mr-2">{t('pages.social.status')}:</span>
              <select
                onChange={(e) => setStatus(e.target.value)}
                disabled={isActing}
                value={status}
                className="text-[16px] p-2 rounded-lg mr-2 shadow-md transition duration-300 disabled:opacity-50"
              >
                <option value="" disabled selected>
                  Selecciona una opción
                </option>
                <option value="approved">
                  {t('pages.social.approved')}
                </option>
                <option value="rejected">
                  {t('pages.social.rejected')}
                </option>
              </select>
            </div>

            {
              status === FILTER_STATUS.rejected && (
                <>
                  <div className="mt-4">
                    <label htmlFor="rejectionReason" className="block text-sm font-medium text-gray-700">
                      {t('generics.reason')}:
                    </label>
                    <textarea
                      id="rejectionReason"
                      value={rejectionReason}
                      onChange={(e) => setRejectionReason(e.target.value)}
                      className="mt-1 p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md resize-y"
                      style={{ maxHeight: '200px' }}
                    />
                  </div>
                </>
              )
            }
            {
              status && (
                <button type="button" className="mt-4 py-2 px-4 bg-primary-l-m block mx-auto text-white rounded-lg shadow-md hover:bg-primary-l-d transition duration-300" onClick={handleSave}>
                  {t('generics.save')}
                </button>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};